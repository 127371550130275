import React, { useState, useEffect } from 'react';
import './accelerometer.css';
import ReactAccelerometer from 'react-accelerometer-field';
import ReactHowler from 'react-howler'
import audio from '../../assets/audio/audio1.mp3';
import bgTop from '../../assets/images/bg-top.jpg';
// @assets/logo
import ettaLogo from '../../assets/logo/etta-logo.svg';

const Accelerometer = (props) => {
    const [pstate, setPState] = useState(false);
    const [accstate, setaccState] = useState(false);
    const [constate, setconState] = useState(false);
    const [soundAudio, setSoundAudio] = useState(false);
    const [countAudio, setCountAudio] = useState(false);
    const reactHowlerAudio =  <ReactHowler src={[audio]} playing={soundAudio} onEnd={onaudio1complete}/>;

    let count = 0;

    function onaudio1complete(){
        setCountAudio(true);
    }
    useEffect(() => {
        window.addEventListener('deviceorientation', handleOrientation);
        function handleOrientation(event) {
            let canvas = document.getElementById('canvas_output');
            betterOutput(event, canvas)
        }
        function betterOutput(event, canvas) {
            if (canvas) {
                canvas.width = window.innerWidth;
                canvas.height = window.innerHeight;
                var context = canvas.getContext('2d');
                var location0 = {
                    x: canvas.width,
                    y: canvas.height,
                }
                var location = {
                    x: location0.x,
                    y: event['beta'] + (canvas.height/2.5),
                }
                context.setLineDash([19, 16])
                context.beginPath()
                context.moveTo(0, location.y)
                context.lineTo(location.x, location.y)
                context.lineWidth = 6
                context.strokeStyle = 'white'
                context.stroke()
            }
        }
    }, []);

    return (
        <div>
            <ReactAccelerometer>
                {(position, rotation) => {
                    if (!position || !accstate) {
                        setconState(true);
                    }
                    else {
                        setconState(false);
                        setSoundAudio(true);
                        if (position.y.toFixed(2) > 9 || position.y.toFixed(2) < -9) {
                            setPState(true);
                            count = count + 1;
                            if ((count > 100) && countAudio === true) {
                                    props.history.push('/pose-estimation/');
                            }
                        }
                        else {
                            setPState(false);
                        }
                    }
                    return (
                        <div>
                            {
                                constate
                                    ? (
                                        <div>
                                            <div className={'accbutton-pg__dgn-wrp'}>
                                                <img src={bgTop}
                                                    className={'accbutton-pg__bg-dgn'}
                                                    alt={'background-design'}
                                                />
                                                <img src={ettaLogo}
                                                    className={'accbutton-pg__logo'}
                                                    alt={'etta-design'}
                                                />
                                            </div>
                                            <button className={'accbutton__pg-get-strt'} id="accelPermsButton" onClick={() => {
                                                if (typeof (DeviceMotionEvent) !== "undefined" && typeof (DeviceMotionEvent.requestPermission) === "function") {

                                                    DeviceMotionEvent.requestPermission()
                                                        .then(response => {
                                                            setaccState(true);
                                                            if (response === 'granted') {
                                                                console.log("accelerometer permission granted");
                                                            }
                                                            else {
                                                                <div>Accelerometer not supported</div>
                                                            }
                                                        })
                                                        .catch(console.error)
                                                } else {
                                                    alert("DeviceMotionEvent is not Supported.");
                                                }
                                            }}>
                                                Please grant permission to access accelerometer
                                            </button>
                                        </div>
                                    ) :
                                    (<div>
                                       { countAudio ? (<></>):(<div> {reactHowlerAudio}</div>)}
                                        {
                                            pstate
                                                ? (
                                                  <div>
                                                        <div className='acc-scan acc-scan__bg-success'>
                                                            <div className={'acc-scan__vl'}></div>
                                                            <canvas id="canvas_output" className={'acc-scan__hr'}></canvas>
                                                            <div className={`acc-scan__cnt`}>
                                                                <div className='acc-scan__top-sec'>
                                                                    <p className='acc-scan__ph-setup'>Phone Setup</p>
                                                                    <div className='acc-scan__ph-finst'>
                                                                        <p>Place your phone on the floor </p>
                                                                        <p>against a wall with the screen </p>
                                                                        <p>facing you.</p>
                                                                    </div>
                                                                </div>
                                                                <div className={`acc-scan__mid-sec-su`}>
                                                                    <div className='acc-scan__tar-rect'>
                                                                        <div className='acc-scan__tar-cnt'>
                                                                            <div className='acc-scan__tar-cir'></div>
                                                                        </div>
                                                                    </div>
                                                                    <p className='acc-scan__tar-success'>Success</p>
                                                                </div>
                                                                <div className='acc-scan__angle-sec'>
                                                                    <div className='acc-scan__ph-angle'>
                                                                        <p>Angle your phone to line up the</p>
                                                                        <p>crosshairs until they intersect</p>
                                                                        <p>in the box.</p>
                                                                    </div>
                                                                </div>
                                                                <div className='acc-scan__btm-sec'>
                                                                    <div className={'acc-scan__bt-sec-cnt'}>
                                                                        <div className={'acc-scan__bt-lf-arc'}></div>
                                                                        <div className={'acc-scan__bt-ball'}></div>
                                                                        <div className={'acc-scan__bt-rg-arc'}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                : (
                                                    <div>
                                                        <div className='acc-scan acc-scan__bg-no-success'>
                                                            <div className={'acc-scan__vl'}></div>
                                                            <canvas id="canvas_output" className={'acc-scan__hr'}></canvas>
                                                            <div className={`acc-scan__cnt`}>
                                                                <div className='acc-scan__top-sec'>
                                                                    <p className='acc-scan__ph-setup'>Phone Setup</p>
                                                                    <div className='acc-scan__ph-finst'>
                                                                        <p>Place your phone on the floor </p>
                                                                        <p>against a wall with the screen </p>
                                                                        <p>facing you.</p>
                                                                    </div>
                                                                </div>
                                                                <div className={`acc-scan__mid-sec`}>
                                                                    <div className='acc-scan__tar-rect'>
                                                                        <div className='acc-scan__tar-cnt'>
                                                                            <div className='acc-scan__tar-cir'></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='acc-scan__angle-sec'>
                                                                    <div className='acc-scan__ph-angle'>
                                                                        <p>Angle your phone to line up the</p>
                                                                        <p>crosshairs until they intersect</p>
                                                                        <p>in the box.</p>
                                                                    </div>
                                                                </div>
                                                                <div className='acc-scan__btm-sec'>
                                                                    <div className={'acc-scan__bt-sec-cnt'}>
                                                                        <div className={'acc-scan__bt-lf-arc'}></div>
                                                                        <div className={'acc-scan__bt-ball'}></div>
                                                                        <div className={'acc-scan__bt-rg-arc'}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                        })
                                    </div>
                                    )
                            }
                        </div>)
                }}
            </ReactAccelerometer>
        </div>
    );
}

export default Accelerometer;