import React, { Component } from 'react';
import './password-page.css';
import { Link } from 'react-router-dom';
// @components
import Button from './../../components/button/button';
// @assets/images
import bgTop from '../../assets/images/bg-top.jpg';
// @assets/logo
import ettaLogo from '../../assets/logo/etta-logo.svg';
// @assets/icons
import InfoAltIcon from '../../assets/icons/info-alt.svg';
class PasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: ''
        }
        this.inputFocus = React.createRef();
    }
    componentDidMount() {
        this.inputFocus.current.focus();
    }
    nameHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value },
        )
    };

    continueHandler = (password) => {
        console.log(password);
        localStorage.setItem('password', password);
        return this.props.history.push('/re-password');
    }
    render() {
        return (
            <div className={'pwd-pg__wrp'}>
                <div className={'pwd-pg__dgn-wrp'}>
                    <img src={bgTop}
                        className={'pwd-pg__bg-dgn'}
                        alt={'background-design'}
                    />
                    <img src={ettaLogo}
                        className={'pwd-pg__logo'}
                        alt={'etta-design'}
                    />
                </div>
                <div className={'pwd-pg__cnt-wrp'}>
                    <div className={'pwd-pg__qa-wrp'}>
                        <p className={'pwd-pg__wh-qs'}>Create your password.&#160;<img className='pwd-pg_info-alt' src={InfoAltIcon} alt='info-alt' /></p>
                        <input
                            type={'password'}
                            placeholder={'Your Password'}
                            pattern={/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/}
                            name="password" onChange={this.nameHandler}
                            ref={this.inputFocus}
                            value={this.state.password}
                            className={'pwd-pg__an-em'} />
                    </div>
                    <div className={'pwd-pg__btn-wrp'}>
                        <Link to={'/email-address-field'}><Button
                            className={'back-btn'}
                            type={'button'}
                            onClick={this.backBtnHandler}
                            name={'BACK'}
                        /></Link>
                        <span style={{ marginRight: '15px' }}></span>
                        <Button
                            className={'continue-btn'}
                            type={'button'}
                            onClick={() => this.continueHandler(this.state.password)}
                            disabled={this.state.password.length !== 0 ? false : true}
                            name={'CONTINUE'}
                        />
                    </div>
                </div>
                <div className={'pwd-pg__dots'}>
                    <span className={'pwd-pg__dot'}></span>
                    <span className={'pwd-pg__dot'}></span>
                    <span className={'pwd-pg__act-dot'}></span>
                    <span className={'pwd-pg__dot'}></span>
                    <span className={'pwd-pg__dot'}></span>
                </div>
            </div>
        );
    }
}

export default PasswordPage;