import { Component } from 'react';
import './button.css';

class Button extends Component {
    render() {


        const { type, name, className, onClick, disabled } = this.props;

        return (
            <div>
                <button
                    className={className}
                    onClick={onClick}
                    disabled={disabled}
                    type={type}>
                    {name}
                </button>
            </div>
        )
    }
}

export default Button;