import React, { Component } from 'react';
import './email-page.css';
import { Link } from 'react-router-dom';
// @components
import Button from '../../components/button/button';
// @assets/images
import bgTop from '../../assets/images/bg-top.jpg';
// @assets/logo
import ettaLogo from '../../assets/logo/etta-logo.svg';
class EmailPage extends Component {
   constructor(props) {
       super(props);
       this.nextPageRef = React.createRef();
   }
    render() {
        return (
            <div className={'eml-pg__wrp'}>
                <div className={'eml-pg__dgn-wrp'}>
                    <img src={bgTop}
                        className={'eml-pg__bg-dgn'}
                        alt={'background-design'}
                    />
                    <img src={ettaLogo}
                        className={'eml-pg__logo'}
                        alt={'etta-design'}
                    />
                </div>
                <div className={'eml-pg__cnt-wrp'}>
                    <div className={'eml-pg__qa-wrp'}>
                        <p className={'eml-pg__wh-qs'}>Create a login for your account.</p>
                        <Link to={'/email-address-field'}><input
                            onChange={this.nextPageHandler}
                            name='email'
                            type={'email'}
                            ref={this.nextPageRef}
                            placeholder={'email@emailaddress.com'}
                            className={'eml-pg__an-em'} />
                        </Link>
                    </div>
                    <div className={'eml-pg__btn-wrp'}>
                        <Link to={'/name'}><Button
                            className={'back-btn'}
                            type={'button'}
                            onClick={this.backBtnHandler}
                            name={'BACK'}
                        /></Link>
                        <span style={{ marginRight: '15px' }}></span>
                        <Link to={'/email-address-field'}><Button
                            className={'continue-btn'}
                            type={'button'}
                            onClick={''}
                            disabled={'disabled'}
                            name={'CONTINUE'}
                        /></Link>
                    </div>
                </div>
                <div className={'eml-pg__dots'}>
                    <span className={'eml-pg__dot'}></span>
                    <span className={'eml-pg__act-dot'}></span>
                    <span className={'eml-pg__dot'}></span>
                    <span className={'eml-pg__dot'}></span>
                    <span className={'eml-pg__dot'}></span>
                </div>
            </div>
        );
    }
}

export default EmailPage;