import React, { Component } from 'react';
import './re-enter-pwd-field-page.css';
import ReactTooltip from "react-tooltip";
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
// @components
import Button from './../../components/button/button';
// @assets/icons
import InfoAltIcon from '../../assets/icons/info-alt.svg';
import hidePwd from '../../assets/icons/hide-pwd.svg';
import showPwd from '../../assets/icons/show-pwd.svg';
class ReEnterPwdFieldPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            repass: '',
            values: { password: "", showPassword: false }
        }
        this.inputFocus = React.createRef();
    }
    componentDidMount() {
        this.inputFocus.current.focus();
    }
    nameHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value },
        )
    }
    continueHandler = (e, repass) => {
        const password = localStorage.getItem("newpass");
        repass=this.state.values.password
        const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        const isOk = re.test(repass);
        if(isOk)
        {
            if (repass === password) 
            {
               localStorage.setItem('password', repass);
               return this.props.history.push('/height-birth');
             }
            else
             {
                toast("Password mismatch")
             }
        }
        else
        {
           toast("Password Invalid")
        }
    }    

    handleClickShowPassword = (e) => {
        e.preventDefault();
        let values = { ...this.state.values };
        values["showPassword"] = !values.showPassword;
        this.setState({ values },()=>{
            console.log("values",this.state.values.password)
        });
      };
    
      handlePasswordChange = (e, prop) => {
        let values = { ...this.state.values };
        values[prop] = e.target.value;
        this.setState({ values });
      };

    render() {
        let { values } = this.state;
        return (
            <div className={'rpwd-fd-pg__wrp'}>
                <div className={'rpwd-fd-pg__cnt-wrp'}>
                    <div className={'rpwd-fd-pg__qa-wrp'}>
                        <p className={'rpwd-fd-pg__wh-qs'}>Re-enter your password.&#160;
                            <img className='rpwd-fd-pg_info-alt' src={InfoAltIcon} alt='info-alt'
                                data-tip data-for="registerTip" data-event="click" data-delay-hide="3000"
                            />
                        </p>
                        <span className={'rpwd-fd-pg_ipt-blk'}>
                            <input
                                ref={this.inputFocus}
                                className={'rpwd-fd-pg__an-em'} 
                                type={values.showPassword ? "text" : "password"}
                                onChange={(e) => this.handlePasswordChange(e, "password")}
                                value={values.password}
                            />
                            <a
                                onClick={this.handleClickShowPassword}
                            >
                                {values.showPassword ? <img 
                                className={'rpwd-fd-pg_hde-pwd'} 
                                src={showPwd} 
                                alt='show-password'
                            /> : <img 
                                    className={'rpwd-fd-pg_hde-pwd'} 
                                    src={hidePwd} 
                                    alt='hide-password'
                                />
                                }
                            </a>                            
                            <ReactTooltip id="registerTip" place="top" effect="solid" type="light" 
                                offset="{'left': 100}"
                                arrow-color="#333333"
                                className="customClass"
                                arrowColor="#e0e0ff"
                                afterShow={() => { setTimeout(ReactTooltip.hide(),3000)}}
                            >
                                <div className="tooltip-info">
                                    <p>{'At least 8 characters'}</p>
                                    <p>{'uppercase and lowercase letters.'}</p>
                                    <p>{'letters and numbers.'}</p>
                                    <p>{'one special character, e.g., ! @ # ? }'}</p>
                                </div>
                            </ReactTooltip>
                        </span>
                        <p className={'rpwd-fd-pg__pwd-tl'}>Your password</p>
                    </div>
                    <div className={'rpwd-fd-pg__btn-wrp'}>
                        <Link to={'/re-enter-new-password'}><Button
                            className={'back-btn'}
                            type={'button'}
                            onClick={this.backBtnHandler}
                            name={'BACK'}
                        /></Link>
                        <span style={{ marginRight: '15px' }}></span>
                        <Button
                            className={'continue-btn'}
                            type={'button'}
                            onClick={(e) => this.continueHandler(e, this.state.values)}
                            disabled={this.state.values.password.length !== 0 ? false : true}
                            name={'CONTINUE'}
                        />
                        <Toaster/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReEnterPwdFieldPage;