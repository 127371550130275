import React, { Component } from 'react';
import './forgot-pwd-page.css';
import axios from 'axios';
import { Link } from 'react-router-dom';

// @assets/images
import bgTop from '../../assets/images/bg-top.jpg';

// @assets/logo
import ettaLogo from '../../assets/logo/etta-logo.svg';
// import { Link } from 'react-router-dom';

class ForgotPwdPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resetemail: ''

        }
    }
    nameHandler = (event) => {
        this.setState({
            resetemail: event.target.value
        })
    };



    continueHandler = (resetemail) => {
        var formData = new FormData();
        formData.append("email_id", this.state.resetemail);
        localStorage.setItem('resetemail', resetemail);


        console.log(localStorage.getItem('email'));
        axios.post(`https://ettaapi1.coitor.com/forgotpassword`, formData)

            .then(res => {
                console.log(res);
                console.log(res.data.msg);
                if(res.data.msg=="Invalid Request!"){
                    alert("Not Registered")
                }
                else{
                console.log(res.data.user_id);
                localStorage.setItem("user_id", res.data.user_id);
                return this.props.history.push('/forgot-password-results');
                }
            })
            .catch((err) => { alert(err.response.data); console.log(err) });


        
    }
    render() {
        return (
            <div className={'fp-pg__wrp'}>

                <div className={'fp-pg__dgn-wrp'}>
                    <img src={bgTop}
                        className={'fp-pg__bg-dgn'}
                        alt={'background-design'}
                    />
                    <img src={ettaLogo}
                        className={'fp-pg__logo'}
                        alt={'etta-design'}
                    />

                </div>

                <div className={'fp-pg__cnt-wrp'}>
                    <div className={'fp-pg__qa-wrp'}>
                        <p className={'fp-pg__wh-qs'}>What’s your email address?</p>
                        <input
                            type={'email'}
                            placeholder={'email@emailaddress.com'}
                            name="resetemail" onChange={this.nameHandler}

                            value={this.state.resetemail}
                            className={'fp-pg__an-em'} />
                    </div>

                    <div className={'fp-pg__lnks-wrp'}>

                        <button className={'fp-pg__snd-rse-btn'} onClick={() => this.continueHandler(this.state.resetemail)}
                            disabled={this.state.resetemail.length !== 0 ? false : true}>SEND RESET EMAIL</button>
                        <Link to ={'/sign-in-field'}>
                        <p className={'fp-pg__cnl-btn'}>CANCEL</p>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgotPwdPage;