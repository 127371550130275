import React, { Component } from 'react';
import './authentication-page.css';
import ReactTooltip from "react-tooltip";
import { Link } from 'react-router-dom';

// @assets/images
import bgTop from '../../assets/images/bg-top.jpg';

// @assets/logo
import ettaLogo from '../../assets/logo/etta-logo.svg';

// @assets/icons
import InfoAltIcon from '../../assets/icons/info-alt.svg';
import axios from 'axios';

class AuthenticationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otp: ''

        }
    }
    nameHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    };

    otpHandler = (otp) => {
        var userid = localStorage.getItem("userid");
        console.log(localStorage.getItem('userid'));
        var formData = new FormData();

        formData.append("user_id", userid);


        axios.post('https://ettaapi1.coitor.com/resendotp', formData)
            .then(res => {
                console.log(res);
                console.log(res.data.msg);

            })
            .catch((err) => { console.log(err.response.data.message); console.log(err.response) });
        console.log(localStorage.getItem('email'));
    };



    continueHandler = (otp) => {


        var userid = localStorage.getItem("userid");
        localStorage.setItem("otp", otp);
        console.log("otp", this.state.otp);
        console.log("userid", userid);
        var formData = new FormData();
        var status;
        formData.append("otp", this.state.otp);
        formData.append("user_id", userid);
        
        axios.post('https://ettaapi1.coitor.com/verifyotp', formData)
            .then(res => {
                console.log(res);
                console.log(res.data.msg);
                status = res.data.status;
                console.log(status);
                localStorage.setItem("status", res.data.status);
                console.log("status=", status);
                if (status === 1) {
                    alert("Successfully Verified Your Account !");
                    return this.props.history.push('/demo');
                }
                else {
                    alert("Invalid OTP");
                }
            })
            .catch((err) => {
                console.log(err.response.data.message); status = err.response.data.status;
                console.log("status=", status);
                if (status === 1) {
                    alert("Otp verified");
                    return this.props.history.push('/demo');
                }
                else {
                    alert("Successfully Verified Your Account !");
                }
                console.log(status); localStorage.setItem("status", err.response.data.status); console.log(err.response)
                
            });

        

    }
    render() {
        return (
            <div className={'auth-pg__wrp'}>

                <div className={'auth-pg__dgn-wrp'}>
                    <img src={bgTop}
                        className={'auth-pg__bg-dgn'}
                        alt={'background-design'}
                    />
                    <img src={ettaLogo}
                        className={'auth-pg__logo'}
                        alt={'etta-design'}
                    />
                </div>
                <div className={'auth-pg__cnt-wrp'}>
                    <div className={'auth-pg__qa-wrp'}>
                        <p className={'auth-pg__wh-qs'}>
                            Check your email for <br />
                            a verification code.&#160;
                            <img className='auth-pg__info-alt' src={InfoAltIcon} alt='info-alt' 
                                data-tip data-for="registerTip" data-event="click" data-delay-hide="3000"
                            />
                            <ReactTooltip id="registerTip" place="top" effect="solid" type="light"
                                offset="{'left': 85, 'top':30}"
                                arrow-color="#333333"
                                className="customClass"
                                arrowColor="#e0e0ff"
                                afterShow={() => { setTimeout(ReactTooltip.hide(),3000)}}
                            >
                                <div className="tooltip-info">
                                    <p>{'Then, enter the code below.'}</p>                                
                                </div>
                            </ReactTooltip>
                        </p>
                        <Link to={'/authentication-field'}>
                        <input
                            type={'number'}
                            placeholder={'0000'}
                            name="otp" onChange={this.nameHandler}
                            value={this.state.otp}
                            className={'auth-pg__an-em'} />
                            </Link>
                        <p className={'auth-pg__snd-ncd'} onClick={() => this.otpHandler(this.state.otp)}>SEND A NEW CODE</p>
                    </div>
                    <div className={'auth-pg__lnks-wrp'}>
                        <button className={'auth-pg__snd-rse-btn'}
                            onClick={() => this.continueHandler(this.state.otp)}
                            disabled={this.state.otp.length !== 0 ? false : true}
                        >CREATE ACCOUNT</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AuthenticationPage;