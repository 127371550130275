import React, { Component } from 'react';
import './vid-record.css';
import logo from '../../assets/gif/7.gif'
import ReactHowler from 'react-howler';
import axios from 'axios';
import audio1 from '../../assets/audio/audio4.mp3';
import audio2 from '../../assets/audio/audio5.mp3';
import audio3 from '../../assets/audio/audio6.mp3';
import audio4 from '../../assets/audio/audio7.mp3';
import ImgProcess from '../../components/img-process/img-process';
import ImgProcess1 from '../../components/img-process1/img-process1';
import ImgProcess2 from '../../components/img-process2/img-process2';
axios.defaults.timeout = 40000;
const videoType = 'video/webm';

class PoseEstimation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detected: false,
            recording: false,
            recording1: false,
            videos: [],
            loader: false,
            loader1: false,
            loader2: false,
            loader3: false,
            start_loader: false,
            recording2: false,
            recording3: false,
            recording4: false
        };
        this.modelLoaded = false;
    }
    onaudio1complete = () => {
        this.setState({
            recording: true,
        })
        this.startRecording();

        console.log("audio1 end");
    };

    onaudio2complete = () => {
        this.setState({
            recording: true,
            recording2: false,
            recording3: true
        })
        console.log("audio2 end");
        setTimeout(() => {
            document.getElementById("video").style.visibility = "hidden";
        }, 2500);
    }
    onaudio3complete = () => {

        this.stopRecording();
        setTimeout(() => {
            this.saveVideo();
            this.setState({
                start_loader: true,
                loader: true,
                recording4: true,
                recording3: false,
                recording2: false
            });
        }, 500);
        console.log("audio3 end");
    }
    onaudio4complete = () => {
        console.log("audio4 end");
        this.setState({
            loader: false,
            loader1: true,
            recording4: false
        });
        setTimeout(() => {

            this.setState({
                loader1: false,
                loader2: true
            });
            setTimeout(() => {

                this.setState({

                    loader2: false,
                    loader3: true,

                });

            }, 7 * 1000);

        }, 7 * 1000);
    }

    startRecording() {
        console.log("recording")
        this.chunks = [];
        this.mediaRecorder.start(10);
        this.setState({
            recording2: true

        });



    }

    stopRecording() {
        document.getElementById("video").style.visibility = "hidden";
        this.mediaRecorder.stop();

        this.state = {

            recording1: true,

        };
    }

    saveVideo() {
        this.stream.getTracks().forEach(track => track.stop());
        const blob = new Blob(this.chunks, { type: videoType });
        const videoFile = new File([blob], "video.mp4", {
            type: "video/mp4"
        })
       
        console.log(videoFile)
        
           
            var fd = new FormData();
            const height = localStorage.getItem('height')
            const email = localStorage.getItem('email')
            const gender=localStorage.getItem('gender');
            fd.append("height", height);
            fd.append("email", email)
            fd.append("gender", gender)          
            fd.append("file", videoFile);
            console.log(height,email,videoFile);
            axios.post('https://ettames.coitor.com/upload_file', fd)
            .then(res => {
                const email=localStorage.getItem('email');
                // console.log(res.data.data[0].hip_to_ankle_cm);
                this.props.history.push('/social');  
            })
            .catch((err) => {
                console.log(err);
               this.props.history.push('/social');
            });
    }


    componentDidMount() {

        if (window.screen.orientation === 'landscape') {
            this.webCamRef.current.style.transform = 'rotate(90deg)';
        }
        this.ua = navigator.userAgent.toLowerCase();
        this.is_safari = (this.ua.indexOf("safari/") > -1 && this.ua.indexOf("chrome") < 0);
        console.log(this.is_safari);
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            throw new Error(
                'Browser API navigator.mediaDevices.getUserMedia not available');
        }
        navigator.mediaDevices
            .getUserMedia({
                'audio': false,
                'video': {
                    facingMode: 'user',
                    frameRate: 12, // Reduce this if there's a stuttering in feed
                },
            }).then(res => {

                if (res != null) {
                    console.log(this.video);
                    this.stream = res;
                    this.video.srcObject = this.stream;

                    this.video.play();

                    if (this.is_safari) {
                        this.mediaRecorder = new MediaRecorder(this.stream, {
                            type: "video/mp4",

                            canvas: {
                                width: 640,
                                height: 640
                            },

                        });
                    }
                    else {

                        this.mediaRecorder = new MediaRecorder(this.stream, {
                            mimeType: videoType,
                        });
                    }
                    this.chunks = [];

                    this.mediaRecorder.ondataavailable = e => {
                        if (e.data && e.data.size > 0) {
                            this.chunks.push(e.data);



                        }
                    };



                }
            });

    }

    componentWillUnmount() {
        if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
        }
    }


    render() {
        const { recording } = this.state;
        return (
            <div >
                {
                    this.state.start_loader
                        ? <div className="lod__wrp">
                            {
                                this.state.loader ?
                                    (
                                        <ImgProcess/>
                                    )
                                    : (
                                        null
                                    )}
                            {
                                this.state.loader1
                                    ? (
                                        <ImgProcess1/>

                                    ) :
                                    (null)
                            }
                            {
                                this.state.loader2
                                    ? (
                                        <ImgProcess2/>
                                    )
                                    : (null)
                            }
                            {
                                this.state.loader3
                                    ? (<div className={'gif2'}  >
                                        <div className="img-pro3__wrp-btn-meet">Loading Your Light Body </div>
                                        <img src={logo} alt="loading..." className={'gif1'} />
                                        
                                    </div>)
                                    : (<div></div>)
                            }
                        </div>
                        : <video id="video" muted playsInline autoPlay ref={v => { this.video = v; }} className={'po-est__vid'}   ></video>
                }


                {
                    recording
                        ? (<div></div>)
                        : (
                            <ReactHowler
                                src={audio1}
                                playing={true}
                                loop={false}
                                onEnd={this.onaudio1complete}
                                volume={1}
                            />
                        )
                }

                {
                    this.state.recording2
                        ? (<ReactHowler
                            src={audio2}
                            playing={true}
                            loop={false}
                            onEnd={this.onaudio2complete}
                            volume={1}
                        />
                        )
                        : (<div></div>)
                }
                {
                    this.state.recording4
                        ? (<ReactHowler
                            src={audio4}
                            playing={true}
                            onEnd={this.onaudio4complete}
                            loop={false}
                            volume={1}
                        />
                        )
                        : (<div></div>)
                }
                {
                    this.state.recording3
                        ? (<ReactHowler
                            src={audio3}
                            playing={true}
                            onEnd={this.onaudio3complete}
                            loop={false}
                            volume={1}
                        />
                        )
                        : (<div></div>)
                }
                <div className='acc-scan__btm-sec'>
                    <div className={'acc-scan__bt-sec-cnt'}>
                        <div className={'acc-scan__bt-lf-arc'}></div>
                        <div className={'acc-scan__bt-ball'}></div>
                        <div className={'acc-scan__bt-rg-arc'}></div>
                    </div>
                </div>
            </div>
        )
    }

}

export default PoseEstimation;