import React, { Component } from 'react';
import './email-field-page.css';
import validator from 'validator'
import toast, { Toaster } from 'react-hot-toast';
// @components
import Button from './../../components/button/button';
import { Link } from 'react-router-dom';
class EmailFieldPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ''
        }
        this.inputFocus = React.createRef();
    }
    componentDidMount() {
        this.inputFocus.current.focus();
    }
    nameHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    continueHandler = (email) => {
        if (validator.isEmail(email)) {
            localStorage.setItem('email', email);
            return this.props.history.push('/create-new-password');
          } else {
            toast('Email Invalid')
          }  
    }
    render() {
        return (
            <div className={'eml-fd-pg__wrp'}>
                <div className={'eml-fd-pg__cnt-wrp'}>
                    <div className={'eml-fd-pg__qa-wrp'}>
                        <p className={'eml-fd-pg__wh-qs'}>Create a login for your account.</p>
                        <input
                            type={'text'}
                            name="email" onChange={this.nameHandler}
                            ref={this.inputFocus}
                            value={this.state.email}
                            className={'eml-fd-pg__an-em'} />
                        <p className={'eml-fd-pg__pwd-tl'}>email@emailaddress.com</p>
                    </div>
                    <div className={'eml-fd-pg__btn-wrp'}>
                        <Link to={'/email-address'}>
                            <Button
                            className={'back-btn'}
                            type={'button'}
                            onClick={''}
                            name={'BACK'}
                            />
                        </Link>
                        <span style={{ marginRight: '15px' }}></span>
                        <Button
                            className={'continue-btn'}
                            type={'button'}
                            onClick={() => this.continueHandler(this.state.email)}
                            disabled={this.state.email.length !== 0 ? false : true}
                            name={'CONTINUE'}
                        />
                        <Toaster/>
                    </div>
                </div>
            </div>
        );
    }
}

export default EmailFieldPage