import React, { useRef, useEffect, useState } from 'react';
import './pose-estimation.css';
import * as tf from '@tensorflow/tfjs';
import * as posenet from '@tensorflow-models/posenet';
import WebCam from 'react-webcam';
import whPoseMan from '../../assets/images/group-21@3x.png';
import PoseMan from '../../assets/images/group-21@3x(1).png';
import Empty from '../../assets/images/empty.png';
import ReactHowler from 'react-howler'
import audio from '../../assets/audio/audio2.mp3';
import audio1 from '../../assets/audio/audio3.mp3';
const PoseEstimation = (props) => {
    const [pstate, setPState] = useState(false);
    const [audiodetect, setAudiodetect] = useState(true);
    const webcamRef = useRef(null);
    let images = [whPoseMan, PoseMan];
    let imageURL = Empty;
    let state = 0;
    let count = 0;
    let clearSet;

    function audioend(){
        setAudiodetect(false);
    }
    useEffect(() => {

        // load posenet
        const runPosenet = async () => {
            const net = await posenet.load({
                inputResolution: { width: 460, height: 360 },
                scale: 0.5
            })

            // set Interval
            clearSet = setInterval(() => {
                detect(net);
            }, 100);
        }

        const detect = async (net) => {
            if (typeof webcamRef.current !== "undefined" && webcamRef.current !== null && webcamRef.current.video.readyState === 4) {
                // Get Video Properties

                const video = webcamRef.current.video;
                
                const videoWidth = webcamRef.current.video.videoWidth;
                const videoHeight = webcamRef.current.video.videoHeight;
               
                // Set video width
                webcamRef.current.video.width = videoWidth;
                webcamRef.current.video.height = videoHeight;

                // Make Detections
                const pose = await net.estimateSinglePose(video);
                console.log(pose,state);
                if (pose.score > 0.5 && state == 0 && audiodetect===false) {
                    document.getElementById("border").style.visibility = "hidden";

                    document.getElementById("front_image").src = images[0];
                    setPState(true);
                    console.log(count)
                    count++;
                    if (count > 8) {
                        count = 0
                        state = 1;
                    }

                }
                if (state == 0) {
                    imageURL = images[0];
                }
                else if (state == 1) {
                    
                    let leftShoulder = pose.keypoints[5]["position"]['y'];
                    let leftWrist = pose.keypoints[9]["position"]['y'];
                    let rightShoulder = pose.keypoints[6]["position"]['y'];
                    let rightWrist = pose.keypoints[10]["position"]['y'];
                    if (leftShoulder - leftWrist > 20 && rightShoulder - rightWrist > 20 && pose.score > 0.5) {
                        count++;
                        if (count > 8) {  
                            props.history.push('/pose-record');
                            
                        }
                        else {

                            document.getElementById("front_image").src = images[1];
                            console.log(images[1])
                           
                        }
                    }
                }
            }
        }
        
        runPosenet();

    })


    return (
        <div>

            <div className='pose-est'>
                <div className='pose-est__wrp'>
                <div>
                    <div>
                    {console.log(imageURL)}
                    <img id='front_image'  className='pose-est__canvas'  src={imageURL} />
                    <WebCam ref={webcamRef} autoPlay className={'po-est__vid'} mirrored={true} audio={false} />
                    </div></div>
                
                    <div id="border" className='cam-bxa__bdr-con'></div>
                </div>

            </div>
            <div className='acc-scan__btm-sec'>
                <div className={'acc-scan__bt-sec-cnt'}>
                    <div className={'acc-scan__bt-lf-arc'}></div>
                    <div className={'acc-scan__bt-ball'}></div>
                    <div className={'acc-scan__bt-rg-arc'}></div>
                </div>
            </div>

            {pstate ? (
                <div>
                {console.log(audio1)}
                <ReactHowler src={audio1} playing={true} volume={1}/></div>

            ) : (audiodetect?(    <div>
                <ReactHowler src={audio} playing={true} onEnd={audioend} volume={1}/>{console.log(audio1)}</div>):(null)

            )}
        </div>

    );


}

export default PoseEstimation;