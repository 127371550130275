import React, { Component } from 'react';
import './gender-page.css'
import axios from 'axios';
import ReactTooltip from "react-tooltip";

import { Link } from 'react-router-dom';

// @components
import Button from './../../components/button/button';

// @assets/images
import bgTop from '../../assets/images/bg-top.jpg';

// @assets/logo
import ettaLogo from '../../assets/logo/etta-logo.svg';

// @assets/icons
import InfoAltIcon from '../../assets/icons/info-alt.svg';

class GenderPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gender: '',
            select1:true

        }
        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleClick1 = this.handleClick1.bind(this);
        this.handleClick2 = this.handleClick2.bind(this);
        this.handleClick3 = this.handleClick3.bind(this);

        this.handlebdayChange = this.handlebdayChange.bind(this);
    }
    handlebdayChange(evt) {
        this.setState({ gender: evt.target.value });
        if (this.state.gender) {
            this.setState({ value: true });
        }
        
    };
    handleClick1() {
        var gen = "Female";
        localStorage.setItem('gender', gen);
       
        this.setState({ gender: gen });
        console.log(this.state.gender);
    }
    handleClick2() {
        var gen = "Male";
        localStorage.setItem('gender', gen);      
        this.setState({ gender: gen });
        console.log(this.state.gender);
    }
    handleClick3() {
        var gen = "Non binary";
        localStorage.setItem('gender', gen);
        console.log(gen);
        this.setState({ gender: gen });
        console.log(this.state.gender);
    
    }
    nameHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    };
    handleSubmit(e) {
        // const { gender } = this.state;


        
        var firstname = localStorage.getItem("fname");
        var lastname = localStorage.getItem("lname");
        var email = localStorage.getItem("email");
        var password = localStorage.getItem("password");
        var height = localStorage.getItem("height");
        var bday = localStorage.getItem("bday");
        var gender_data = localStorage.getItem("gender");
        console.log(firstname);
        console.log(lastname);
        console.log(email);
        console.log(password);
        console.log(height);
        console.log(bday);
        console.log(gender_data);

        this.setState({ select1: false });
        //this.setState({ select1: true });
        var formData = new FormData();
        formData.append("firstname", firstname);
        formData.append("lastname", lastname);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("height", height);
        formData.append("date_of_birth", bday);
        formData.append("gender", gender_data);
       // this.setState({ select1: true });
        console.log(gender_data);
        console.log(localStorage.getItem('email'));
        axios.post('https://ettaapi1.coitor.com/signup', formData)
            .then(res => {
                // console.log(res);
                // console.log(res.data.msg);
                // console.log(res.data.user_id);
                localStorage.setItem("userid", res.data.user_id);
                this.props.history.push('/authentication')
            })
            .catch((err) => { console.log(err.response.data);alert("Invalid mail or Already Registered");  console.log(err.response); localStorage.setItem("userid", err.response.user_id); });
        //this.props.history.push('/authentication')




    }

    continueHandler = (gender) => {
        var firstname = localStorage.getItem("fname");
        var lastname = localStorage.getItem("lname");
        var email = localStorage.getItem("email");
        var password = localStorage.getItem("password");
        localStorage.setItem("pass",password);
        var height = localStorage.getItem("height");
        var bday = localStorage.getItem("bday");
        var gender_data = localStorage.getItem("gender");
        console.log(firstname);
        console.log(lastname);
        console.log(email);
        console.log(password);
        console.log(height);
        console.log(bday);
        console.log(gender_data);

        this.setState({ select1: false });
        var formData = new FormData();
        formData.append("firstname", firstname);
        formData.append("lastname", lastname);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("height", height);
        formData.append("date_of_birth", bday);
        formData.append("gender", gender_data);

        console.log(gender_data);
        console.log(localStorage.getItem('email'));
        axios.post('https://ettaapi1.coitor.com/signup', formData)
            .then(res => {
                console.log(res);
                console.log(res.data.msg);
                console.log(res.data.user_id);
                localStorage.setItem("userid", res.data.user_id);
                this.props.history.push('/authentication')
            })
            .catch((err) => { console.log(err.response.data);alert("Invalid mail or Already Registered"); console.log(err.response); localStorage.setItem("userid", err.response.user_id); });
        


     //   return this.props.history.push('/authentication');
    }
    render() {
        return (
            <div className={'gnd-pg__wrp'}>

                <div className={'gnd-pg__dgn-wrp'}>
                    <img src={bgTop}
                        className={'gnd-pg__bg-dgn'}
                        alt={'background-design'}
                    />
                    <img src={ettaLogo}
                        className={'gnd-pg__logo'}
                        alt={'etta-design'}
                    />

                </div>

                <div className={'gnd-pg__cnt-wrp'}>
                    <div className={'gnd-pg__qa-wrp'}>
                        <p className={'gnd-pg__wh-qs'}>
                            Select your preferred gender.&#160;
                            <img className='gnd-pg_info-alt' src={InfoAltIcon} alt='info-alt' 
                                data-tip data-for="registerTip" data-event="click" data-delay-hide="3000"
                            />
                            <ReactTooltip id="registerTip" place="top" effect="solid" type="light"
                                offset="{'left': 75}"
                                arrow-color="#333333"
                                className="customClass"
                                arrowColor="#e0e0ff"
                                afterShow={() => { setTimeout(ReactTooltip.hide(),3000)}}
                            >
                                <div className="tooltip-info">
                                    <p>{'Please select your gender.'}</p>                                
                                </div>
                            </ReactTooltip>
                        </p>
                        
                        <div className={'gnd-pg__an-wp'}>
                            <p>
                                <input type="radio" id="toggle-female" name="toggle" />
                                <label htmlFor="toggle-female" name="gender" onClick={this.handleClick1}
                                    // onChange={this.handlebdayChange}

                                    value={this.state.gender}>Female</label></p>
                            <p>
                                <input type="radio" id="toggle-male" name="toggle" />
                                <label htmlFor="toggle-male" name="gender" onClick={this.handleClick2}
                                    // onChange={this.nameHandler}
                                    value={this.state.gender}>Male</label>
                            </p>
                            <p>
                                <input type="radio" id="toggle-non-binary" name="toggle" />
                                <label htmlFor="toggle-non-binary" name="gender" onClick={this.handleClick3}
                                    //  onChange={this.nameHandler}
                                    value={this.state.gender}>Non-binary</label>
                            </p>
                        </div>
                    </div>

                    <div className={'gnd-pg__cur-st-wrp'}>
                        <div className={'gnd-pg__btn-wrp'}>
                            <Link to={'/height-birth'}>
                                <Button
                                    className={'back-btn'}
                                    type={'button'}
                                    onClick={this.backBtnHandler}
                                    name={'BACK'}
                                /></Link>
                            <span style={{ marginRight: '15px' }}></span>
                            {/* <Link to={'/authentication'}> */}
                                <Button
                                    className={'continue-btn'}
                                    type={'button'}
                                    onClick={this.handleSubmit}
                                    disabled={this.state.select1 && this.state.gender.length!==0 ? false : true}
                                    name={'CONTINUE'}
                                />
                                {/* </Link> */}
                        </div>

                    </div>

                </div>

                <div className={'gnd-pg__dots'}>
                    <span className={'gnd-pg__dot'}></span>
                    <span className={'gnd-pg__dot'}></span>
                    <span className={'gnd-pg__dot'}></span>
                    <span className={'gnd-pg__dot'}></span>
                    <span className={'gnd-pg__act-dot'}></span>
                </div>
            </div>
        )
    }
}

export default GenderPage;