import React, { Component } from 'react';
import './create-new-pwd-field.css';
import ReactTooltip from "react-tooltip";
import toast, { Toaster } from 'react-hot-toast';
// @components
import Button from './../../components/button/button';
// @assets/images
import hidePwd from '../../assets/icons/hide-pwd.svg';
import showPwd from '../../assets/icons/show-pwd.svg';
// @assets/icons
import InfoAltIcon from '../../assets/icons/info-alt.svg';
import { Link } from 'react-router-dom';
class CreateNewPwdPageField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newpass: '',
			values: { password: "", 
            showPassword: false }
        }
        this.inputFocus = React.createRef();
    }
    nameHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value }
        )
    }

    componentDidMount() {
        this.inputFocus.current.focus();
    }

    continueHandler = (newpass) => {
		const password = localStorage.getItem("newpass");
        newpass=this.state.values.password
        const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        const isOk = re.test(newpass);
        console.log(isOk,newpass);        
        if(isOk){
            localStorage.setItem('newpass', newpass);
            console.log("newpass",newpass);
			console.log("password",password)
            return this.props.history.push('/re-enter-new-password');
            }
            else{
                toast("Password Invalid")
            }
    }
	handleClickShowPassword = (e) => {
        e.preventDefault();
        let values = { ...this.state.values };
        values["showPassword"] = !values.showPassword;
        this.setState({ values },()=>{
            console.log("values",this.state.values.password)
        });
      };
      handlePasswordChange = (e, prop) => {
        let values = { ...this.state.values };
        values[prop] = e.target.value;
        this.setState({ values });
      };

    render() {
        let { values } = this.state;
        return (
            <div className={'cnp-pg__wrp page-field'}>
                <div className={'cnp-pg__cnt-wrp margin-top-field'}>
                        <div className={'cnp-pg__qa-wrp'}>
                            <p className={'cnp-pg__wh-qs'}>
                                Create a new password.&#160;
                                <img className='cnp-pg__info-alt' src={InfoAltIcon} 
                                    data-tip data-for="registerTip" data-event="click" data-delay-hide="3000" 
                                    alt='info-alt' 
                                />
                            </p>
                          	<span className={'rpwd-fd-pg_ipt-blk'}>
                            <input
                                ref={this.inputFocus}
                                className={'rpwd-fd-pg__an-em cnp-pg__an-em'} 
                                type={values.showPassword ? "text" : "password"}
                                onChange={(e) => this.handlePasswordChange(e, "password")}
                                value={values.password}
                            />
                            <a
                                onClick={this.handleClickShowPassword}
                            >
                                {values.showPassword ? <img 
                                className={'rpwd-fd-pg_hde-pwd'} 
                                src={showPwd} 
                                alt='show-password'
                            /> : <img 
                                    className={'rpwd-fd-pg_hde-pwd'} 
                                    src={hidePwd} 
                                    alt='hide-password'
                                />
                                }
                            </a>   
                            <ReactTooltip id="registerTip" place="top" effect="solid" type="light" 
                                offset="{'left': 100}"
                                arrow-color="#333333"
                                className="customClass"
                                arrowColor="#e0e0ff"
                                afterShow={() => { setTimeout(ReactTooltip.hide(),3000)}}
                            >
                                <div className="tooltip-info">
                                    <p>{'At least 8 characters'}</p>
                                    <p>{'uppercase and lowercase letters.'}</p>
                                    <p>{'letters and numbers.'}</p>
                                    <p>{'one special character, e.g., ! @ # ? }'}</p>
                                </div>
                            </ReactTooltip>
							</span>
                            <p className={'rpwd-fd-pg__pwd-tl'}>Your password</p>
                        </div>
                    <div className={'cnp-pg__btn-wrp'}>
                        <Link to={'/create-new-password'}><Button
                            className={'back-btn'}
                            type={'button'}
                            onClick={this.backBtnHandler}
                            name={'BACK'}
                        /></Link>
                        <span style={{ marginRight: '15px' }}></span>
                            <Button
                                className={'continue-btn'}
                                type={'button'}
                                onClick={() => this.continueHandler(this.state.values)}
                                disabled={this.state.values.password.length !== 0 ? false : true}
                                name={'CONTINUE'}
                            />
                            <Toaster/>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateNewPwdPageField;