import {
    HashRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

// @splash
import Splash from './../splash/splash';

// @screens
import HomePage from '../screens/home-page/home-page';
import NamePage from '../screens/name-page/name-page';
import EmailPage from './../screens/email-page/email.page';
import PasswordPage from '../screens/password-page/password-page';
import RePasswordPage from './../screens/re-password-page/re-password-page';
import HeightBirthPage from '../screens/height-birth-page/height-birth-page';
import GenderPage from './../screens/gender-page/gender-page';
import TermsPage from '../screens/terms-condition-page/term';
import AuthenticationPage from '../screens/authentication-page/authentication-page';
import AuthenticationPageField from '../screens/authentication-page-field/authentication-page-field';
import NameFieldPage from './../screens/name-field-page/name-field-page';
import EmailFieldPage from './../screens/email-field-page/email-field-page';
import ReEnterPwdFieldPage from './../screens/re-enter-pwd-field-page/re-enter-pwd-field-page';
import CreateNewPwdPage from '../screens/create-new-pwd-page/create-new-pwd-page';
import CreateNewPwdPageField from '../screens/create-new-pwd-field/create-new-pwd-field'
import ReEnterNewPwdPage from './../screens/re-enter-new-pwd-page/re-enter-new-pwd-page';
import SignInPage from './../screens/sign-in-page/sign-in-page';
import ForgotPwdPage from '../screens/forgot-pwd-page/forgot-pwd-page';
import ForgotPwdResultsPage from './../screens/forgot-pwd-results-page/forgot-pwd-results-page';
import SignInFieldPage from '../screens/sign-in-field-page/sign-in-field-page';
import accountmanage from './../screens/accountmanage/accountmanage';
import accountmanageedit from './../screens/accountmanage/accountmanageedit';

import recorderenb from './../new-screens/recorder-enable/recorder-enable';
import volPage from '../new-screens/volume-check-page/volume-check-page';
import demoPage from '../new-screens/quick-explain-video/quick-explain-video';
import scan from '../new-screens/scan-start/scan-start';
import pose1 from '../new-screens/pose-screen1/pose-screen1';
import poseesti from '../new-screens/pose-estimation/pose-estimation';
import vidrecord from '../new-screens/recording/vid-record';
import accPage from './../new-screens/accelerometer/accelerometer';
import social from './../new-screens/social-media/social-media';


const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route exact path={'/'} component={Splash} />
                <Route exact path={'/home'} component={HomePage} />
                <Route exact path={'/terms'} component={TermsPage} />
                <Route exact path={'/name'} component={NamePage} />
                <Route exact path={'/recorderenb'} component={recorderenb} />
                <Route exact path={'/email-address'} component={EmailPage} />
                <Route exact path={'/password'} component={PasswordPage} />
                <Route exact path={'/re-password'} component={RePasswordPage} />
                <Route exact path={'/height-birth'} component={HeightBirthPage} />
                <Route exact path={'/gender'} component={GenderPage} />
                <Route exact path={'/authentication'} component={AuthenticationPage} />
                <Route exact path={'/authentication-field'} component={AuthenticationPageField} />
                <Route exact path={'/sign-in'} component={SignInPage} />
                <Route exact path={'/forgot-password'} component={ForgotPwdPage} />
                <Route exact path={'/forgot-password-results'} component={ForgotPwdResultsPage} />
                <Route exact path={'/create-new-password'} component={CreateNewPwdPage} />
                <Route exact path={'/create-new-pass-field'} component ={CreateNewPwdPageField} />
                <Route exact path={'/re-enter-new-password'} component={ReEnterNewPwdPage} />
                <Route exact path={'/pose-estimation'} component={poseesti} />
                <Route exact path={'/pose-record'} component={vidrecord} />
                <Route exact path={'/social'} component={social} />
                <Route exact path={'/accountmanageedit'} component={accountmanageedit} />
                <Route exact path={'/accountmanage'} component={accountmanage} />
                <Route exact path={'/vol'} component={volPage} />
                <Route exact path={'/acc'} component={accPage} />
                <Route exact path={'/demo'} component={demoPage} />
                <Route exact path={'/scan-start'} component={scan} />
                <Route exact path={'/posescreen1'} component={pose1} />
                <Route exact path={'/name-field'} component={NameFieldPage} />
                <Route exact path={'/email-address-field'} component={EmailFieldPage} />
                <Route exact path={'/re-enter-pwd-field'} component={ReEnterPwdFieldPage} />
                <Route exact path={'/sign-in-field'} component={SignInFieldPage} />
            </Switch>
        </Router>
    );
}

export default Routes;