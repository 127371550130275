import React, { Component } from 'react';
import './social-media.css';

import modalmale from '../../assets/images/male.png';

//API
import axios from 'axios';






class SocialMedia extends Component {   
    componentDidMount() {
       var mail= localStorage.getItem('email');
       console.log(mail);
      
   
        axios.post('https://ettames.coitor.com/attempt')
        .then(res => {
            console.log('res -------->', res['data']);
            localStorage.setItem("text",res['data']['data'])
          
        })
        .catch((err) => {
            console.log(err.response);
            
         
        });
    
  
    }

    handleOnSubmit = async () => {
       
            // caches.keys().then((names) => {
            //   names.forEach((name) => {
            //     caches.delete(name);
            //   });
            // });
        
           
        
        alert("successfully logged out");
        this.props.history.push('/');
        
       
         
    }
    handleOnSubmit1 = async () => {
       

    
   
 
    fetch(modalmale)
        .then(res => res.blob())

        .then(blob => {
            const file = new File([blob], "modal.png", {
                type: 'image/png'
            });
            var text_to_send=localStorage.getItem("text")
           
            console.log(file);
            if (navigator.share) {
                navigator.share({
                    title: "title",
                    text: text_to_send,
                    url: "https://heyetta2.coitor.com",
                    files: [file]
                })
                    .then(() => console.log('Successful share'))
                    .catch((error) => console.log(error));
            
         } else {
                console.log(`system does not support sharing files.`);
            }

        })
        .catch((err) => {
            console.log(err.response.data.message);
            console.log(err.response)
        });
}
    
    

    render() {
        return (
            <div className="so-md">
                <div className="so-md__img">
                    
                    <img src={modalmale} className="so-md__img_space"></img> 
     
                    <div className={'so-md__header'}>
                        <div className={'so-md__header-obj'}>


                            <div ref={ref => (this.mount = ref)} id="container"  />
                           
                        </div>
                       
                        

                    </div>
                </div>
           
                <div className="so-md__btn">
             
                        <button className="share-btn" onClick={this.handleOnSubmit1}>SHARE</button>
               
                    
                    <span style={{ marginRight: '15px' }}></span>
                 
                    <button className="logout-btn"  onClick={this.handleOnSubmit}>LOGOUT</button>
                 
                </div>
                


                <div className={'so-md__bt-sec-wrp'}>
                    <div className={'so-md__bt-sec-cnt'}>
                        <div className={'so-md__bt-lf-arc'}></div>
                        <div className={'so-md__bt-ball'}></div>
                        <div className={'so-md__bt-rg-arc'}></div>
                    </div>
                </div>
            </div>
        )
    }
}


export default SocialMedia;