import React from 'react';
import { Link } from 'react-router-dom';
let state=1;
const WebcamStreamCapture = (props) => {
  const webcamRef = React.useRef(null);
  console.log(webcamRef);
  const mediaRecorderRef = React.useRef(null);
  console.log(mediaRecorderRef)
  if(window.MediaRecorder){
    props.history.push('/acc');
  }
    return (
      <div>
        {!window.MediaRecorder ? (
                  <div>
                  <p>Kindly Enable the Media Recorder Option from Your Mobile Settings as bellow</p>
                  <p>Go to Settings  → Safari → Preferences → Advanced </p>
                  <p>Develop → Experimental Features  →  Enable MediaRecorder</p>
                  </div>
                  ) : (
                    <Link to={'/acc'}></Link>
                  )}
      </div> 
    );
  };
  
export default WebcamStreamCapture;
  