import React, { Component } from "react";
import { Link } from "react-router-dom";
import validator from "validator";
import toast, { Toaster } from "react-hot-toast";

//CSS
import "./accountmanage.css";

//API
import axios from "axios";

// @assets/images
import close from "../../assets/images/group.png";
import modal from "../../assets/images/male.png";

class accpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accfname: "",
      acclname: "",
      accheight: "",
      accbday: "",
      accpass: "",
      accemail: "",
      datacheck: true,
      datacheckbdate: true,
      datacheckpass: true,
      menuCollapse: true,

      isMaleModel: localStorage.getItem("gender") == "Female" ? false : true,
      status: null,
    };
    //API call for getting user details
    var formData = new FormData();
    formData.append("email", localStorage.getItem("email"));
    axios
      .post("https://ettaapi1.coitor.com/getdetails", formData)
      .then((res) => {
        console.log("res.data ----------->", res["data"]["User_details"]);
        localStorage.setItem(
          "height1",
          res["data"]["User_details"][0]["height"]
        );
        this.setState({
          accheight: res["data"]["User_details"][0]["height"],
        });
        localStorage.setItem("email1", res["data"]["User_details"][0]["email"]);
        this.setState({
          accemail: res["data"]["User_details"][0]["email"],
        });
        localStorage.setItem(
          "gender1",
          res["data"]["User_details"][0]["gender"]
        );
        localStorage.setItem(
          "fname1",
          res["data"]["User_details"][0]["first_name"]
        );
        this.setState({
          accfname: res["data"]["User_details"][0]["first_name"],
        });

        localStorage.setItem(
          "lname1",
          res["data"]["User_details"][0]["last_name"]
        );
    
        this.setState({
          acclname: res["data"]["User_details"][0]["last_name"],
        });
        localStorage.setItem(
          "bday1",
          res["data"]["User_details"][0]["date_of_birth"]
        );
        this.setState({
          accbday: res["data"]["User_details"][0]["date_of_birth"],
        });
        localStorage.setItem(
          "password1",
          res["data"]["User_details"][0]["pass_code"]
        );
        this.setState({
          accpass: res["data"]["User_details"][0]["pass_code"],
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
    this.inputFocus = React.createRef();
  }
  //Function to share content
  handleOnSubmit = async () => {
    var gender = localStorage.getItem("gender");
    fetch(modal)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "modal.png", {
          type: "image/png",
        });
        if (navigator.share) {
          navigator
            .share({
              title: "title",
              text: localStorage.getItem("text"),
              url: "https://heyetta2.coitor.com",
              files: [file],
            })
            .then(() => console.log("Successful share"))
            .catch((error) => console.log(error));
        } else {
          console.log(`system does not support sharing files.`);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  userHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  userHandler1 = (accemail) => {
    toast("Email cannot be edited");
  };
  //API for updating user details
  continueHandler = (
    accfname,
    acclname,
    accheight,
    accbday,
    accemail,
    accpass
  ) => {
    var firstname = accfname;
    var lastname = acclname;

    var email = accemail;
    //   } else {
    //     alert('Enter valid Email!')
    //   }
    var password = accpass;
    var height = accheight;
    var bday = accbday;

    var gender_data = localStorage.getItem("gender1");

    var formData = new FormData();
    formData.append("first_name", accfname);
    
    formData.append("last_name", acclname);
    if (validator.isEmail(accemail)) {
      formData.append("email", accemail);
      this.setState({
        datacheck: true,
      });
    } else {
      toast("Invalid EmailID");
      this.setState({
        datacheck: false,
      });
    }
    console.log("name", accfname);

    if(accheight > 0)
    {
        formData.append("height", accheight);
        this.setState({
            datacheck: true,
        });

    }else{
        toast("Invalid Height");
        this.setState({
          datacheck: false,
        });
    }
   

    var now = new Date();
    var birthDate = new Date(bday);
    if (now - birthDate > 410240376000) {
      localStorage.setItem("bday", bday);
      formData.append("date_of_birth", bday);
      this.setState({
        datacheckbdate: true,
      });
    } else {
      toast("Age must be above 13");
      this.setState({
        datacheckbdate: false,
      });
    }
    formData.append("gender", gender_data);
    const re = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    const isOk = re.test(accpass);
    console.log(isOk, accpass);
    if (isOk) {
      console.log("accpass", accpass);
      formData.append("password", accpass);
      this.setState({
        datacheckpass: true,
      });
    } else {
      toast("Password Invalid");
      this.setState({
        datacheckpass: false,
      });
    }

    axios
      .post("https://ettaapi1.coitor.com/updatedetails", formData)
      .then((res) => {
        console.log(res);
        console.log(res.data.user_id);
        localStorage.setItem("userid", res.data.user_id);
        if (
          this.state.datacheck === true && this.state.datacheckbdate === true && this.state.datacheckpass === true
        ) {
          toast("Successfully updated");
          this.props.history.push("/accountmanage");
        }
      })
      .catch((err) => {
       // toast("Invalid emailID or Already Registered");
       // localStorage.setItem("userid", err.response.user_id);
      });
  };
  render() {
    return (
      <div className={"acc-manage__wrp"}>
        <div className={"acc-manage__rect"}>
          <Link to={"/demo"}>
            <img className={"acc-manage__rect-grp"} src={close} />
          </Link>
          <div className={"acc-manage__rect-my-acc"}>
            <p>My Account </p>
          </div>
          <div>
            <div>
              <label className={"acc-manage__rect-det"}>First name:</label>
              <input
                type="text"
                className={"acc-manage__rect-det-fn"}
                name="accfname"
                onChange={this.userHandler}
                value={this.state.accfname}
              />
              <br />
            </div>
            <div>
              <label className={"acc-manage__rect-det"}>Last name:</label>
              <input
                type={"text"}
                className={"acc-manage__rect-det-ln"}
                name="acclname"
                onChange={this.userHandler}
                value={this.state.acclname}
              />
            </div>
            <div>
              <label className={"acc-manage__rect-det"}>Height:</label>
              <input
                type={"text"}
                className={"acc-manage__rect-det-he"}
                name="accheight"
                onChange={this.userHandler}
                value={this.state.accheight}
              />
            </div>
            <div>
              <label className={"acc-manage__rect-det"}>Birthday:</label>
              <input
                type={"text"}
                className={"acc-manage__rect-det-bd"}
                name="accbday"
                onChange={this.userHandler}
                value={this.state.accbday}
              />
            </div>
            <div>
              <label className={"acc-manage__rect-det"}>Email:</label>
              <input
                type={"text"}
                className={"acc-manage__rect-det-em"}
                name="accemail"
                disabled={true}
                onChange={this.userHandler1}
                value={this.state.accemail}
              />
            </div>
            <div>
              <label className={"acc-manage__rect-det"}>Password:</label>
              <input
                type={"password"}
                className={"acc-manage__rect-det-pw"}
                name="accpass"
                onChange={this.userHandler}
                value={this.state.accpass}
              />
            </div>
          </div>
          <div>
            <button
              className={"acc-manage__rect-save"}
              onClick={() =>
                this.continueHandler(
                  this.state.accfname,
                  this.state.acclname,
                  this.state.accheight,
                  this.state.accbday,
                  this.state.accemail,
                  this.state.accpass
                )
              }
            >
              SAVE
            </button>
            <Toaster />
          </div>
          <div className="acc-manage__rect-comm">
            <button
              className="acc-manage__rect-share"
              onClick={this.handleOnSubmit}
            >
              SHARE
            </button>
            <span style={{ marginRight: "7vw" }}></span>
            <Link to={"/demo"}>
              <button className="acc-manage__rect-updt">UPDATE SCAN</button>
            </Link>
          </div>
        </div>
        <div className={"so-md__bt-sec-wrp"}>
          <div className={"so-md__bt-sec-cnt"}>
            <div className={"so-md__bt-lf-arc"}></div>
            <div className={"so-md__bt-ball"}></div>
            <div className={"so-md__bt-rg-arc"}></div>
          </div>
        </div>
      </div>
    );
  }
}

export default accpage;
