import React, { Component } from 'react';
import './pose-screen1.css';
import WebCam from 'react-webcam';
class CameraBoxAlign extends Component {
    constructor(props) {
        super(props);
        this.webcamRef = React.createRef();
    }
    componentDidMount() {
        console.log("start")
        setTimeout(() => {
                this.props.history.push('/recorderenb');
        }, 1000)
    }
    render() {
        const videoConstraints = {
            facingMode: "user"
        };
        return (
            <div>
                <WebCam audio={false} videoConstraints={videoConstraints} ref={this.webcamRef}/>       
            </div>
        );
    }
}
export default CameraBoxAlign;