import React, { useEffect } from 'react';
import './splash.css';

// @images/logo
import ettaSplashLogo from '../assets/logo/etta-splash.svg';

const Splash = (props) => {
    useEffect(() => {
        setTimeout(() => {
            props.history.push('/home');
        }, 3000)
    })

    return (
        <div className='splash'>
            <div className='splash-cnt'>
                <img className='splash-logo'
                    src={ettaSplashLogo}
                    alt="etta" />
            </div>
        </div>
    );
}

export default Splash;