import React, { Component } from 'react';
import './create-new-pwd-page.css';
import ReactTooltip from "react-tooltip";
import { Link } from 'react-router-dom';
// @components
import Button from './../../components/button/button';
// @assets/images
import bgTop from '../../assets/images/bg-top.jpg';
import hidePwd from '../../assets/icons/hide-pwd.svg';
import showPwd from '../../assets/icons/show-pwd.svg';
// @assets/logo
import ettaLogo from '../../assets/logo/etta-logo.svg';
// @assets/icons
import InfoAltIcon from '../../assets/icons/info-alt.svg';
class CreateNewPwdPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newpass: '',
			values: { password: "", showPassword: false }
        }
        this.inputFocus = React.createRef();
    }
    nameHandler = (event) => {
        this.setState({
            newpass: event.target.value
        })
    };
  
    continueHandler = (newpass) => {
		const password = localStorage.getItem("newpass");
        newpass=this.state.values.password
        const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        const isOk = re.test(newpass);
        console.log(isOk,newpass);        
        if(isOk){
            localStorage.setItem('newpass', newpass);
            return this.props.history.push('/re-enter-new-password');
            }
        else{
                alert("Kindly enter valid password")
            }
    }
	
	handleClickShowPassword = (e) => {
        e.preventDefault();
        let values = { ...this.state.values };
        values["showPassword"] = !values.showPassword;
        this.setState({ values },()=>{
            console.log("values",this.state.values.password)

        });
      };
    
      handlePasswordChange = (e, prop) => {
        let values = { ...this.state.values };
        values[prop] = e.target.value;
        this.setState({ values });
      };
	  
    render() {
        let { values } = this.state;
        return (
            <div className={'cnp-pg__wrp'}>
                <div className={'cnp-pg__dgn-wrp'}>
                    <img src={bgTop}
                        className={'cnp-pg__bg-dgn'}
                        alt={'background-design'}
                    />
                    <img src={ettaLogo}
                        className={'cnp-pg__logo'}
                        alt={'etta-design'}
                    />
                </div>
                <div className={'cnp-pg__cnt-wrp'}>
                        <div className={'cnp-pg__qa-wrp'}>
                            <p className={'cnp-pg__wh-qs'}>
                                Create a new password.&#160;
                                <img className='cnp-pg__info-alt' src={InfoAltIcon} 
                                    data-tip data-for="registerTip" data-event="click" data-delay-hide="3000" 
                                    alt='info-alt' 
                                />
                            </p>
							<span className={'rpwd-fd-pg_ipt-blk'}>
                            <Link to={'/create-new-pass-field'}>
                                <input
                                    type={'password'}
                                    name="newpass" 
                                    placeholder={"Your Password"}
                                    onChange={this.nameHandler}
                                    ref={this.inputFocus}
                                    className={'rpwd-fd-pg__an-em cnp-pg__an-em'} 
                                    value={values.password}
                                />
                            </Link>
                            <a
                                onClick={this.handleClickShowPassword}
                            >
                                {values.showPassword ? <img 
                                className={'rpwd-fd-pg_hde-pwd'} 
                                src={showPwd} 
                                alt='show-password'
                            /> : <img 
                                    className={'rpwd-fd-pg_hde-pwd'} 
                                    src={hidePwd} 
                                    alt='hide-password'
                                />
                                }
                            </a>   
                            <ReactTooltip id="registerTip" place="top" effect="solid" type="light" 
                                offset="{'left': 100}"
                                arrow-color="#333333"
                                className="customClass"
                                arrowColor="#e0e0ff"
                                afterShow={() => { setTimeout(ReactTooltip.hide(),3000)}}
                            >
                                <div className="tooltip-info">
                                    <p>{'At least 8 characters'}</p>
                                    <p>{'uppercase and lowercase letters.'}</p>
                                    <p>{'letters and numbers.'}</p>
                                    <p>{'one special character, e.g., ! @ # ? }'}</p>
                                </div>
                            </ReactTooltip>
							</span>
                        </div>
                    <div className={'cnp-pg__btn-wrp'}>
                        <Link to={'/email-address'}><Button
                            className={'back-btn'}
                            type={'button'}
                            onClick={this.backBtnHandler}
                            name={'BACK'}
                        /></Link>
                        <span style={{ marginRight: '15px' }}></span>
                            <Button
                                className={'continue-btn'}
                                type={'button'}
                                onClick={() => this.continueHandler(this.state.values)}
                                disabled={this.state.values.password.length !== 0 ? false : true}
                                name={'CONTINUE'}
                            />
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateNewPwdPage;