import React, { Component } from 'react';
import './volume-check-page.css';
// @images/icons
import speaker from '../../assets/icons/volume-check-speaker.svg'
import leftArrow from '../../assets/icons/left-arrow.svg'
import { Link } from 'react-router-dom';
import speakerSuccess from '../../assets/icons/speaker-success.svg'
class VolumeCheckPage extends Component {
    constructor(props) {
        super(props);
    this.state = {
        detected: false,
    };
}
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                detected: true,
            });
        }, 2000)
        setTimeout(() => {
            this.props.history.push('/posescreen1');
        }, 6000)
    }    
    render() {
        return (
            <div className={'vck-pg__wrp'}>          
                <div className={'vck-pg__btn-wrp'}>
                    <img className={'vck-pg__lft-arrow'} src={leftArrow} alt='left arrow' />
                    <Link to={'/scan-start'}> <button className={'vck-pg__back-btn'}>BACK</button></Link>
                </div>
                <div className={'vch-pg__instrct-wrp'}>
                    <p className={'vck-pg__ado-inst'}>Audio Instructions</p>
                    <div className={'vck-pg__inc-vol-inst'}>
                        <p>Please increase your volume.</p>
                        <p>You’ll need to be able to hear </p>
                        <p>instructions from a distance.</p>
                    </div>
                    <div className={'vck-pg__vl-ch-wrp'}>
                    {
                    this.state.detected
                        ? <img src={speakerSuccess} className={'vck-pg__vl-ch-ig'} alt='volume-speaker' />
                        : <img src={speaker} className={'vck-pg__vl-ch-ig'} alt='volume-speaker' />
                }  
                    </div>
                </div>
                <div className={'vck-pg__bt-sec-wrp'}>
                    <div className={'vck-pg__bt-sec-cnt'}>
                        <div className={'vck-pg__bt-lf-arc'}></div>
                        <div className={'vck-pg__bt-ball'}></div>
                        <div className={'vck-pg__bt-rg-arc'}></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VolumeCheckPage;