import React, { Component } from 'react';
import './name-field-page.css';
import { Link } from 'react-router-dom';
// @components
import Button from './../../components/button/button';
class NameFieldPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fname: '',
            lname: ''
        }
        this.inputFocus = React.createRef();
    }
    componentDidMount() {
        this.inputFocus.current.focus();
    }
    nameHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    continueHandler = (fname, lname) => {
        localStorage.setItem('fname', fname);
        localStorage.setItem('lname', lname);
        return this.props.history.push('/email-address');
    }
    render() {
        return (
            <div className={'nme-fd-pg'}>
                <div className={'nme-fd-pg__qa-wrp'}>
                    <p className={'nme-fd-pg__qs-wh'}>What should we call you?</p>
                    <input className={'nme-fd-pg__fs-nm'} name="fname" onChange={this.nameHandler}
                        ref={this.inputFocus}
                        value={this.state.fname}
                    />
                    <p className={'nme-fd-pg__fs-tl'}>First name</p>
                    <input className={'nme-fd-pg__ls-nm'} name="lname" onChange={this.nameHandler}
                        value={this.state.lname} />
                    <p className={'nme-fd-pg__ls-tl'}>Last name</p>
                </div>
                <div className={'nme-fd-pg__btn-wrp'}>
                    <Link to = {'/name'}><Button
                        className={'back-btn'}
                        type={'button'}
                        onClick={this.backBtnHandler}
                        name={'BACK'}
                    />
                    </Link>
                    <span style={{ marginRight: '15px' }}></span>
                    <Button
                        className={'continue-btn'}
                        type={'button'}
                        onClick={() => this.continueHandler(this.state.fname, this.state.lname)}
                        name={'CONTINUE'}
                        disabled={this.state.fname.length !== 0 && this.state.lname.length !== 0 ? false : true}
                    />
                </div>
            </div>
        );
    }
}

export default NameFieldPage