import React, { useEffect, useRef } from "react";
import './quick-explain-video.css'
// @assets/icons
import LeftArrow from '../../assets/icons/grey-left-arrow.svg'
import RightArrow from '../../assets/icons/white-right-arrow.svg'
import { Link } from 'react-router-dom';
export default function QuickExplainVideo() {
    const videoEl = useRef(null);
    const attemptPlay = () => {
          videoEl &&
          videoEl.current &&
          videoEl.current.play().catch(error => {
          console.error("Error attempting to play", error);
          });
      };
      useEffect(() => {
        attemptPlay();
      }, []); 
    return (
            <div className='qu-expv'>
                <div className='qu-expv__cnt'>
                    <div className='qu-expv__top-sec'>
                        <Link to={'/home'}>
                            <button className='qu-expv__btn-wrp'>
                                <img src={LeftArrow} className='qu-expv__lft-ar' alt='left arrow' />
                                <label style={{fontSize:"16px", color:"white", marginRight:"0.2vw",letterSpacing:"1.5px"}}>BACK</label>
                            </button></Link>
                        <Link to={'/scan-start'}>
                            <button className='qu-expv__btn-wrp'>
                                <label style={{fontSize:"16px", color:"white",marginLeft:"0.2vw" ,letterSpacing:"1.5px"}}>NEXT</label>
                                <img src={RightArrow} className='qu-expv__rgt-ar' alt='right arrow' />
                            </button></Link>
                    </div>
                    <div>
                    <video
          style={{ height: "88vh", width: "100%", margintop: "-4vh" }}
          playsInline
          muted
          controls
          display="fullscreen"
          alt="All the devices"
          src="https://djwx0cboj9bqk.cloudfront.net/etta-demo.mp4"
          ref={videoEl}
        />
                    </div>
                    <div className='qu-expv__btm-sec'>
                        <div className={'qu-expv__bt-sec-cnt'}>
                            <div className={'qu-expv__bt-lf-arc'}></div>
                            <div className={'qu-expv__bt-ball'}></div>
                            <div className={'qu-expv__bt-rg-arc'}></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }