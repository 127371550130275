import React, { Component } from 'react';
import './sign-in-page.css';
// @assets/images
import bgTop from '../../assets/images/bg-top.jpg';
// @assets/logo
import ettaLogo from '../../assets/logo/etta-logo.svg';
import { Link } from 'react-router-dom';
class SignInPage extends Component {
    constructor(props) {
        super(props);
        this.nextPageRef = React.createRef();
    }
    render() {
        return (
            <div className={'sg-in-pg__wrp'}>
                <div className={'sg-in-pg__dgn-wrp'}>
                    <img src={bgTop}
                        className={'sg-in-pg__bg-dgn'}
                        alt={'background-design'}
                    />
                    <img src={ettaLogo}
                        className={'sg-in-pg__logo'}
                        alt={'etta-design'}
                    />
                </div>
                <div className={'sg-in-pg__cnt-wrp'}>
                    <div className={'sg-in-pg__qa-wrp'}>
                        <p className={'sg-in-pg__wh-qs'}>Hey, nice to see you again.</p>
                       <Link to={'/sign-in-field'}> <input
                            type={'email'}
                            placeholder={'Enter email login'}
                            className={'sg-in-pg__an-em'} /></Link>
                    </div>
                    <div className={'sg-in-pg__lnks-wrp'}>
                        <Link to={'/sign-in-field'}>
                            <button className={'sg-in-pg__snd-rse-btn'}>SIGN IN</button></Link>
                       <Link to={'/terms'}>
                           <p className={'sg-in-pg__cnl-btn'}>SIGN UP</p>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default SignInPage;