import React, { Component } from 'react';
import { Link } from 'react-router-dom'

//CSS
import './accountmanage.css';

//API
import axios from 'axios';

// @assets/images
import modal from '../../assets/images/male.png';
import close from '../../assets/images/group.png'



class accpage extends Component {
    //Function to share content
    handleOnSubmit = async () => {
        
        var gender=localStorage.getItem('gender');
         fetch(modal)
            .then(res => res.blob())
            .then(blob => {
                const file = new File([blob], "modal.png", {
                    type: 'image/png'
                });
              if (navigator.share) {
                   
                    navigator.share({
                        title: "title",
                        text: this.state.text,
                        url: "https://heyetta2.coitor.com",
                        files: [file]
                    })
                        .then(() => console.log('Successful share'))
                        .catch((error) => console.log(error));
             }
             else {
                    alert('system does not support sharing files.');
                }

            })
            .catch((err) => {
             console.log(err.response)
            });
    }

    //API call to get user data
    componentDidMount() {
        axios.post('https://ettames.coitor.com/attempt')
        .then(res => {
            console.log('res -------->', res['data']);
            localStorage.setItem("text",res['data']['data'])
            this.setState({text: res['data']['data']});
        })
        .catch((err) => {
            console.log(err.response);
            
            this.setState({text: "Easy way to measure your body"});
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            accfname: localStorage.getItem('fname'),
            acclname: localStorage.getItem('lname'),
            accheight: localStorage.getItem('height'),
            accbday: localStorage.getItem('bday'),
            accpass: localStorage.getItem('password'),
            accemail: localStorage.getItem('email'),
            text:""
        }
       //API call for getting user details
        var formData = new FormData();
        var email= localStorage.getItem('emaillogin')
        formData.append("email", email );
        axios.post('https://ettaapi1.coitor.com/getdetails', formData)
        .then(res => {
           
            console.log('res.data ----------->', res["data"]["User_details"]);
            localStorage.setItem('height1', res["data"]["User_details"][0]["height"]);
            this.setState({
                accheight: res["data"]["User_details"][0]["height"]
            });
            localStorage.setItem('email1', res["data"]["User_details"][0]["email"]);
            this.setState({
                accemail: res["data"]["User_details"][0]["email"]
            });
            localStorage.setItem('gender1', res["data"]["User_details"][0]["gender"]);
            localStorage.setItem('fname1', res["data"]["User_details"][0]["first_name"]);
            this.setState({
                accfname: res["data"]["User_details"][0]["first_name"]
            });
            
            localStorage.setItem('lname1', res["data"]["User_details"][0]["last_name"]);
            this.setState({
                acclname: res["data"]["User_details"][0]["last_name"]
            });
            localStorage.setItem('bday1', res["data"]["User_details"][0]["date_of_birth"]);
            this.setState({
                accbday: res["data"]["User_details"][0]["date_of_birth"]
            });
            localStorage.setItem('password1', res["data"]["User_details"][0]["pass_code"]);
            this.setState({
                accpass: res["data"]["User_details"][0]["pass_code"]
            });
        })
        .catch((err) => {
            console.log(err.response);
        });
         
        this.inputFocus = React.createRef();
    }

 


    render() {
        return (
            <div className={'acc-manage__wrp'}>
                <div className={'acc-manage__rect'}>
                    <Link to={'/demo'}>
                      <img className={'acc-manage__rect-grp'} src={close}/>
                    </Link>
                    <div className={'acc-manage__rect-my-acc'}>
                       <p>My Account</p> 
                    </div>
                    <div>
                     <div>
                        <label className={'acc-manage__rect-det'}>First name:</label>
                        <input  type={'text'} className={'acc-manage__rect-det-fn'} disabled={true} value={this.state.accfname} name="fname"/><br />
                     </div>
                     <div>
                        <label className={'acc-manage__rect-det'}>Last name:</label>
                        <input  type={'text'} className={'acc-manage__rect-det-ln'} disabled={true}  value={this.state.acclname} name="lname"/>
                     </div>
                     <div>
                        <label className={'acc-manage__rect-det'}>Height:</label>
                        <input  type={'text'} className={'acc-manage__rect-det-he'} disabled={true} value={this.state.accheight} name="height"/>
                     </div>
                     <div>
                        <label className={'acc-manage__rect-det'}>Birthday:</label>
                        <input  type={'text'} className={'acc-manage__rect-det-bd'} disabled={true} value={this.state.accbday} name="bday"/>
                     </div>
                     <div>
                        <label className={'acc-manage__rect-det'}>Email:</label>
                        <input  type={'text'} className={'acc-manage__rect-det-em'} disabled={true} value={this.state.accemail} name="email"/>
                     </div>
                     <div>
                        <label className={'acc-manage__rect-det'}>Password:</label>
                        <input  type={'password'} className={'acc-manage__rect-det-pw'} disabled={true} value={this.state.accpass} name="pass"/></div>
                     </div>
                     <div> 
                         <Link to={'/accountmanageedit'}>
                             <button onClick={this.handleSubmit} className={'acc-manage__rect-edit'}>EDIT</button>
                         </Link>
                     </div>
                     <div>
                      <div >
                        <div className="acc-manage__rect-comm">
                            <button className="acc-manage__rect-share" onClick={this.handleOnSubmit}>SHARE</button>
                            <span style={{ marginRight: '7vw' }}></span>
                            <Link to={'/demo'}>
                               <button className="acc-manage__rect-updt" >UPDATE SCAN</button>
                            </Link>
                        </div>
                      </div>
                     </div>
                </div>
                <div className={'so-md__bt-sec-wrp'}>
                    <div className={'so-md__bt-sec-cnt'}>
                        <div className={'so-md__bt-lf-arc'}></div>
                        <div className={'so-md__bt-ball'}></div>
                        <div className={'so-md__bt-rg-arc'}></div>
                    </div>
                </div>
            </div> 
        )
    }

}

export default accpage;