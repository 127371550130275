import React, { Component } from 'react';
import "./img-process1.css";

import rectangle from '../../assets/images/rectangle.png';
import rect from '../../assets/images/inner-rect.png';
import tub from '../../assets/images/tub.png';

class ImgProcess1 extends Component {
    render() {
        return (
            <div className="img-pro1__wrp">

                <div className="img-pro1__wrp-txt">Loading Your <br /> Light Body </div>
                <div className="img-pro1__wrp-pr-txt">Processing your measurements</div>
                <div className="img-pro1__wrp-imgs">
                    <img src={rectangle} alt="Loader" className="img-pro1__wrp-load-img" ></img>
                    <img src={rect} alt="loader" className="img-pro1__wrp-load-inner-img"></img>
                </div>
                <div className="img-pro1__wrp-tht-txt">
                    That’s the equivilant of <b>2.5</b><br />
                    <b>years of drinking water</b> for <br />
                    the average person
                </div>
                <div className="img-pro1__wrp-tub">
                    <img src={tub} alt="Load-group" className="img-pro1__wrp-tub-img"></img>
                </div>
                <div className="img-pro1__wrp-24-btubs">
                    ...or <b>24 full bathtubs.</b>
                </div>
                <div className={'img-pro1__bt-sec-wrp'}>
                    <div className={'img-pro1__bt-sec-cnt'}>
                        <div className={'img-pro1__bt-lf-arc'}></div>
                        <div className={'img-pro1__bt-ball'}></div>
                        <div className={'img-pro1__bt-rg-arc'}></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ImgProcess1;