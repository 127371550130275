import React, { Component } from 'react';
import './height-birth-page.css';
import ReactTooltip from "react-tooltip";
import { Link } from 'react-router-dom';
// @components
import Button from './../../components/button/button';
// @assets/images
import bgTop from '../../assets/images/bg-top.jpg';
// @assets/icons
import InfoAltIcon from '../../assets/icons/info-alt.svg';
// @assets/logo
import ettaLogo from '../../assets/logo/etta-logo.svg';
class HeightBirthPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            h1: '',
            h2: '',
            bday: ''
        }
    }
    nameHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    };
    continueHandler = (h1, h2, bday) => {
        var height = h1+"." + h2;
        var now = new Date();
        var birthDate = new Date(bday);
        console.log(birthDate,now,now - birthDate);
        if(now - birthDate > 410240376000) {
            localStorage.setItem('bday', bday);
            if(height != null && height != undefined && height != ""){
                localStorage.setItem('height', height);
                return this.props.history.push('/gender');
                }
                else{
                    alert("Select Your Height")
                }
        }
        else
        {
                alert("Age Should be elder than 13")
        }
    }
    render() {
        return (
            <div className={'hbi-pg__wrp'}>
                <div className={'hbi-pg__dgn-wrp'}>
                    <img src={bgTop}
                        className={'hbi-pg__bg-dgn'}
                        alt={'background-design'}
                    />
                    <img src={ettaLogo}
                        className={'hbi-pg__logo'}
                        alt={'etta-design'}
                    />
                </div>
                <div className={'hbi-pg__cnt-wrp'}>
                    <div className={'hbi-pg__qa-wrp'}>
                        <p className={'hbi-pg__wh-qs'}>How tall are you?</p>
                        <div style={{ margin: '17px 0 46px' }}>
                            <select className='hbi-pg__slt-ipt' name="h1" onChange={this.nameHandler}
                                value={this.state.h1} style={{ paddingLeft: "27px" }}>
                                <option disabled selected value="">0 ft</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                            </select>
                            <span style={{ marginRight: '11px' }}></span>
                            <select className='hbi-pg__slt-ipt' name="h2" onChange={this.nameHandler}
                                value={this.state.h2} style={{ paddingLeft: "27px" }}>
                                <option>0 in</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                                <option>10</option>
                                <option>11</option>
                            </select>
                        </div>
                        <p className={'hbi-pg__wh-qs'}>When’s your birthday? &#160;
                            <img className='hbi-pg_info-alt' src={InfoAltIcon} alt='info-alt'
                                data-tip data-for="registerTip" data-event="click" data-delay-hide="3000"
                            />
                        </p>
                        <input type='date' name="bday" onChange={this.nameHandler}
                            max="2020-05-31"
                            value={this.state.bday} className={'hbi-pg__an-dt'}
                        />
                        <ReactTooltip id="registerTip" place="top" effect="solid" type="light"
                            offset="{'left': 120}"
                            arrow-color="#333333"
                            className="customClass"
                            arrowColor="#e0e0ff"
                            afterShow={() => { setTimeout(ReactTooltip.hide(),3000)}}
                        >
                            <div className="tooltip-info">
                                <p>{'This helps ETTA provide you the best fit'}</p>
                                <p>{'recommendations possible.'}</p>                                
                            </div>
                        </ReactTooltip>
                    </div>
                    <div className={'hbi-pg__cur-st-wrp'}>
                        <div className={'hbi-pg__btn-wrp'}>
                            <Link to={'/re-enter-new-password'}>
                                <Button
                                    className={'back-btn'}
                                    type={'button'}
                                    onClick={this.backBtnHandler}
                                    name={'BACK'}
                                /></Link>
                            <span style={{ marginRight: '15px' }}></span>
                                <Button
                                    className={'continue-btn'}
                                    type={'button'}
                                    onClick={() => this.continueHandler(this.state.h1, this.state.h2, this.state.bday)}
                                    disabled={this.state.bday.length !== 0 ? false : true}
                                    name={'CONTINUE'}
                                />
                        </div>
                    </div>
                </div>
                <div className={'hbi-pg__dots'}>
                    <span className={'hbi-pg__dot'}></span>
                    <span className={'hbi-pg__dot'}></span>
                    <span className={'hbi-pg__dot'}></span>
                    <span className={'hbi-pg__act-dot'}></span>
                    <span className={'hbi-pg__dot'}></span>
                </div>
            </div>
        );

    }
}

export default HeightBirthPage;