import React, { Component } from 'react';
import "./img-process2.css";

import rectangle from '../../assets/images/rectangle.png';
import earth from '../../assets/images/earth.png';

class ImgProcess2 extends Component {
    render() {
        return (
            <div className="img-pro2__wrp">
                <div className="img-pro2__wrp-txt">Loading Your <br /> Light Body</div>
                <div className="img-pro2__wrp-process-txt" style={{fontSize:"23px" ,marginTop:"4vh"}}>
                    Processing complete!
                </div>
                <div className="img-pro2__wrp-imgs">
                    <img src={rectangle} alt="Loader" className="img-pro2__wrp-load-img" ></img>
                </div>
                <div className="img-pro2__wrp-tht-txt">
                    Together we can save the<br /> planet.
                </div>
                <div className="img-pro2__wrp-tub">
                    <img src={earth} alt="Load-group" className="img-pro2__wrp-tub-img"></img>
                </div>
                <div className="img-pro2__wrp-scan-txt">
                    One ETTA scan at a time.
                </div>
                <div className="img-pro2__wrp-btn">
                    <button className="img-pro2__wrp-btn-meet">Meet Your Light Body</button>
                </div>
            </div>
        )
    }
}

export default ImgProcess2;