import React, { Component } from 'react';
import './sign-in-field-page.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

// @components
//import Button from './../../components/button/button';
import LargeButton from './../../components/large-button/large-button';

import hidePwd from '../../assets/icons/hide-pwd.svg';
import showPwd from '../../assets/icons/show-pwd.svg';

class NameFieldPasignin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            pass: '',
            status: null,
			values: { password: "", showPassword: false }

        }
        this.inputFocus = React.createRef();
    }

    componentDidMount() {
        this.inputFocus.current.focus();
    }

    nameHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }


    continueHandler = (email, pass) => {
        console.log("pass.password", pass.password);
        
	//	const password = localStorage.getItem("pass");
       
        //pass = this.state.values.password

        // var status;
        var formData = new FormData();
        formData.append("email", this.state.email);
        formData.append("password", pass.password);
        localStorage.setItem('emaillogin', this.state.email);
        localStorage.setItem('pass', pass.password);
        console.log(localStorage.getItem('firstname'));
        console.log(localStorage.getItem('emaillogin'));
        axios.post('https://ettaapi1.coitor.com/login', formData)
            .then(res => {
                console.log(res);
                console.log(res.data.msg);
                this.setState({
                    status: res.data.status
                })
                // status = res.data.status;
                console.log(this.state.status);
                // localStorage.setItem("status", res.data.status);
                console.log("status=", this.state.status);
                if (this.state.status === 302) {
                    alert("Login Success!");
                    var formData = new FormData();
                    formData.append("email", this.state.email);
                    axios.post('https://ettaapi1.coitor.com/getdetails', formData)
                        .then(res => {
                           // console.log('res -------->', res);
                            console.log('res.data ----------->', res["data"]["User_details"]);
                            localStorage.setItem('height', res["data"]["User_details"][0]["height"]);
                            localStorage.setItem('email', res["data"]["User_details"][0]["email"]);
                            localStorage.setItem('gender', res["data"]["User_details"][0]["gender"]);
                            localStorage.setItem('fname', res["data"]["User_details"][0]["first_name"]);
                            localStorage.setItem('lname', res["data"]["User_details"][0]["last_name"]);
                            localStorage.setItem('bday', res["data"]["User_details"][0]["date_of_birth"]);
                            localStorage.setItem('password', res["data"]["User_details"][0]["password_hash"]);
                        })
                        .catch((err) => {
                            console.log(err.response.data.message);
                            alert(err.response.data.message);
                        });
                    this.props.history.push('/accountmanage');
                }
                else {
                    alert("Email and password not found!");

                }
            })
            .catch((err) => {
               // console.log(err.response.data.message);
                this.setState({
                    status: err.response.data.status
                })
                //  status = err.response.data.status;
                console.log("status=", this.state.status);
                if (this.state.status === 302) {
                    alert("Login Success!");
                    var formData = new FormData();
                    formData.append("email", this.state.email);
                    axios.post('https://ettaapi1.coitor.com/getdetails', formData)
                        .then(res => {
                            console.log('res -------->', res);
                            console.log('res.data ----------->',res["data"]["User_details"]);
                            localStorage.setItem('height', res["data"]["User_details"][0]["height"]);
                            localStorage.setItem('email', res["data"]["User_details"][0]["email"]);
                            localStorage.setItem('gender', res["data"]["User_details"][0]["gender"]);
                            localStorage.setItem('fname', res["data"]["User_details"][0]["first_name"]);
                            localStorage.setItem('lname', res["data"]["User_details"][0]["last_name"]);
                            localStorage.setItem('bday', res["data"]["User_details"][0]["date_of_birth"]);
                            localStorage.setItem('password', res["data"]["User_details"][0]["password_hash"]);

                        })
                        .catch((err) => {
                            console.log(err.response.data.message);
                            console.log(err.response)
                        });
                    this.props.history.push('/accountmanage');
                }
                else{
                    alert("Email ID or password is incorrect!");
                }
                // if (this.state.status === 302) {
                //     alert("Login Succeed!");
                //      this.props.history.push('/demo');
                // }
                // else {
                //     alert("Email and password not found!");
                // }
                // console.log(this.state.status); localStorage.setItem("status", err.response.data.status); console.log(err.response)
            });

    }
	
	handleClickShowPassword = (e) => {
        e.preventDefault();
        let values = { ...this.state.values };
        values["showPassword"] = !values.showPassword;
        this.setState({ values },()=>{
            console.log("values",this.state.values.password)

        });
      };
    
      handlePasswordChange = (e, prop) => {
        let values = { ...this.state.values };
        values[prop] = e.target.value;
        this.setState({ values });
      };
	  
    render() {
		let { values } = this.state;
        return (
            <div className={'signin-fd-pg'}>
                <div className={'signin-fd-pg__qa-wrp'}>
                    <p className={'signin-fd-pg__qs-wh'}>Hey, nice to see you again</p>
                    <input className={'signin-fd-pg__fs-nm'} name="email" onChange={this.nameHandler}
                        ref={this.inputFocus}
                        value={this.state.email} />
                    <p className={'signin-fd-pg__fs-tl'}>Email login</p>
                    {/* <input className={'signin-fd-pg__ls-nm'} type="password" name="pass" onChange={this.nameHandler} 
                        ref={this.inputFocus}
                        value={this.state.pass} /> */}
					<span className={'rpwd-fd-pg_ipt-blk'}>
						<input
								// type={'password'}
								// name="newpass" 
								// onChange={this.nameHandler}
								//ref={this.inputFocus}
								// value={this.state.repass}
								className={'signin-fd-pg__ls-nm'} 
								type={values.showPassword ? "text" : "password"}
								onChange={(e) => this.handlePasswordChange(e, "password")}
								value={values.password}
						/>
						<a
							onClick={this.handleClickShowPassword}
							// onMouseDown={this.handleMouseDownPassword}
						>
							{values.showPassword ? <img 
							className={'rpwd-fd-pg_hde-pwd'} 
							src={showPwd} 
                            alt='show-password'
						/> : <img 
								className={'rpwd-fd-pg_hde-pwd'} 
								src={hidePwd} 
								alt='hide-password'
							/>
							}
						</a> 
					</span>
                    <p className={'signin-fd-pg__ls-tl'}>Password</p>
                </div>
                <div>
                    <Link to={'/forgot-password'} className='signin-srn__fp'><p >FORGOT PASSWORD</p></Link>
                </div>

                <div className='signin__lnks'>

                    <LargeButton
                        className={'signin__sign-in'}
                        type={'button'}
                        onClick={() => this.continueHandler(this.state.email, this.state.values)}
                        disabled={this.state.email.length !== 0 && this.state.values.length !== 0 ? false : true}
                        name={'SIGN IN'}
                    />

                    <Link to={'/terms'} className='signin__sign-up'><p >SIGN UP</p></Link>
                </div></div>
        );
    }
}

export default NameFieldPasignin