import React, { Component } from 'react';
import "./img-process.css";

import rectangle from '../../assets/images/rectangle.png';
import shirt from '../../assets/images/shirt.png';
import rect1 from '../../assets/images/inner-rect1.png';

class ImgProcess extends Component {
    render() {
        return (
            <div className="img-pro__wrp">
                <div className="img-pro__wrp-txt">Loading Your <br /> Light Body</div>
                <div className="img-pro__wrp-pr-txt">Processing your measurements</div>
                <div className="img-pro__wrp-imgs">
                    <img src={rectangle} alt="Loader" className="img-pro__wrp-load-img" ></img>
                    <img src={rect1} alt="loader" className="img-pro__wrp-load-inner-img"></img>
                </div>
                <div className="img-pro__wrp-tht-txt">
                    Did you know...
                </div>

                <div className="img-pro__wrp-shirt">
                    <img className="img-pro__wrp-shirt__img" src={shirt} alt="Shirt" />
                </div>
                <div className="img-pro__wrp-2700-txt">
                    it takes <b>2700 litres</b> of water<br /> to produce <br /><b>1 cotton t-shirt.</b>
                </div>
            </div>
        )
    }
}

export default ImgProcess;