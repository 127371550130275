import React, { Component } from 'react';
import './re-password-page.css';
import { Link } from 'react-router-dom';
// @components
import Button from './../../components/button/button';
// @assets/images
import bgTop from '../../assets/images/bg-top.jpg';
// @assets/logo
import ettaLogo from '../../assets/logo/etta-logo.svg';
// @assets/icons
import InfoAltIcon from '../../assets/icons/info-alt.svg';
class RePasswordPage extends Component {
    constructor(props) {
        super(props);
        this.nextPageRef = React.createRef();
    }
    render() {
        return (
            <div className={'rpwd-pg__wrp'}>
                <div className={'rpwd-pg__dgn-wrp'}>
                    <img src={bgTop}
                        className={'rpwd-pg__bg-dgn'}
                        alt={'background-design'}
                    />
                    <img src={ettaLogo}
                        className={'rpwd-pg__logo'}
                        alt={'etta-design'}
                    />
                </div>
                <div className={'rpwd-pg__cnt-wrp'}>
                    <div className={'rpwd-pg__qa-wrp'}>
                        <p className={'rpwd-pg__wh-qs'}>Re-enter your password.&#160;<img className='rpwd-pg_info-alt' src={InfoAltIcon} alt='info-alt' /></p>
                        <Link to={'/re-enter-pwd-field'}><input
                            onChange={this.nextPageHandler}
                            type={'password'}
                            placeholder={'Your Password'}
                            name="repass"
                            ref={this.nextPageRef}
                            className={'rpwd-pg__an-em'} /></Link>
                    </div>
                    <div className={'rpwd-pg__btn-wrp'}>
                        <Link to={'/password'}>
                            <Button
                            className={'back-btn'}
                            type={'button'}
                            onClick={this.backBtnHandler}
                            name={'BACK'}
                            />
                        </Link>
                        <span style={{ marginRight: '15px' }}></span>
                        <Button
                            className={'continue-btn'}
                            type={'button'}
                            onClick={''}
                            disabled={'disabled'}
                            name={'CONTINUE'}
                        />
                    </div>
                </div>
                <div className={'rpwd-pg__dots'}>
                    <span className={'rpwd-pg__dot'}></span>
                    <span className={'rpwd-pg__dot'}></span>
                    <span className={'rpwd-pg__act-dot'}></span>
                    <span className={'rpwd-pg__dot'}></span>
                    <span className={'rpwd-pg__dot'}></span>
                </div>
            </div>
        );
    }
}

export default RePasswordPage;