import React, { Component } from 'react';
import './name-page.css';
import { Link } from 'react-router-dom';
// @components
import Button from './../../components/button/button';
// @assets/images
import bgTop from '../../assets/images/bg-top.jpg';
// @assets/logo
import ettaLogo from '../../assets/logo/etta-logo.svg';
class NamePage extends Component {
    constructor(props) {
        super(props);
        this.nextPageRef = React.createRef();
    }
    render() {
        return (
            <div className={'nme-pg__wrp'}>
                <div className={'nme-pg__dgn-wrp'}>
                    <img src={bgTop}
                        className={'nme-pg__bg-dgn'}
                        alt={'background-design'}
                    />
                    <img src={ettaLogo}
                        className={'nme-pg__logo'}
                        alt={'etta-design'}
                    />
                </div>
                <div className={'nme-pg__cnt-wrp'}>
                    <div className={'nme-pg__qa-wrp'}>
                        <p className={'nme-pg__wh-qs'}>What should we call you?</p>
                    <Link to={'/name-field'}>
                        <input      
                            name='name'
                            type={'text'}
                            ref={this.nextPageRef}
                            placeholder={'Your first and last name'}
                            className={'nme-pg__an-em'} /></Link>
                    </div>
                    <div className={'nme-pg__btn-wrp'}>
                    <Link to={'/terms'}><Button
                            className={'back-btn'}
                            type={'button'}
                            onClick={this.backBtnHandler}
                            name={'BACK'}
                        />
                    </Link>
                        <span style={{ marginRight: '15px' }}></span>
                        <Button
                            className={'continue-btn'}
                            type={'button'}
                            onClick={''}
                            disabled={'disabled'}
                            name={'CONTINUE'}
                        />
                    </div>
                </div>
                <div className={'nme-pg__dots'}>
                    <span className={'nme-pg__act-dot'}></span>
                    <span className={'nme-pg__dot'}></span>
                    <span className={'nme-pg__dot'}></span>
                    <span className={'nme-pg__dot'}></span>
                    <span className={'nme-pg__dot'}></span>
                </div>
            </div>
        );
    }
}
export default NamePage;