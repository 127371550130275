import React, { Component } from 'react';
import './forgot-pwd-results-page.css';

// @assets/images
import bgTop from '../../assets/images/bg-top.jpg';

// @assets/logo
import ettaLogo from '../../assets/logo/etta-logo.svg';



class ForgotPwdResultsPage extends Component {
    render() {
        return (
            <div className={'fpr-pg__wrp'}>

                <div className={'fpr-pg__dgn-wrp'}>
                    <img src={bgTop}
                        className={'fpr-pg__bg-dgn'}
                        alt={'background-design'}
                    />
                    <img src={ettaLogo}
                        className={'fpr-pg__logo'}
                        alt={'etta-design'}
                    />

                </div>

                <div className={'fpr-pg__cnt-wrp'}>
                    <p>Instructions for how to reset </p>
                    <p>your password have been sent. </p>
                </div>

            </div>
        )
    }
}

export default ForgotPwdResultsPage;