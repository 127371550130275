import React, { Component } from 'react';
//CSS
import './home-page.css';
import { Link } from 'react-router-dom'
// @assets/icons
import shirt from '../../assets/icons/shirt.svg';
import reduce from '../../assets/icons/reduce.svg';
import heart from '../../assets/icons/heart.svg';
// @assets/images
import bgTop from '../../assets/images/bg-top.jpg';

var data;
class HomePage extends Component {
    // clearCacheData = () => {
    //     caches.keys().then((names) => {
    //       names.forEach((name) => {
    //         caches.delete(name);
    //       });
    //     });
    // }

    render() {
        // data=this.clearCacheData();
        return (
            <div className={'home__wrp'}>
                <div className={'home__pg-cnt'}>
                    <div className={'home__pg-bg-sec'}>
                        <img className={'home__pg-bg-top-dgn'} src={bgTop} alt="background design" />
                        <div className={'home__pg-bg-instructs'}>
                            <p className={'home__pg-bg-hey'}>Hey,</p>
                            <div className={'home__pg-bg-shop'}>
                                <p>I’m  ETTA – your personal</p>
                                <p> shopping assistant.</p>
                            </div>
                        </div>
                        <div className={'home__pg-three-min'}>
                            <p>Three minutes and a </p>
                            <p>  360º spin from now, you’ll:</p>
                        </div>
                    </div>
                    <div className={'home__pg-things-sec'}>
                        <div className={'home__pg-abt-intro'}>
                            <div className={'home__pg-dgn-oval'}>
                                <img className={'home__pg-shirt-ico'} src={shirt} alt={'shirt'} />
                            </div>
                            <div className={'home__pg-abt-cnt'}>
                                <p>Know your size,   </p>
                                <p> wherever you shop </p>
                            </div>
                        </div>
                        <div className={'home__pg-abt-intro'}>
                            <div className={'home__pg-dgn-oval'}>
                                <img className={'home__pg-reduce-ico'} src={reduce} alt={'reduce'} />
                            </div>
                            <div className={'home__pg-abt-cnt'}>
                                <p>Reduce your </p>
                                <p> carbon footprint </p>
                            </div>
                        </div>
                        <div className={'home__pg-abt-intro'}>
                            <div className={'home__pg-dgn-oval'}>
                                <img className={'home__pg-heart-ico'} src={heart} alt={'heart'} />
                            </div>
                            <div className={'home__pg-abt-cnt'}>
                                <p style={{ marginTop: '12px' }}>Find fashion forever</p>
                            </div>
                        </div>
                    </div>
                    <div className={'home__pg-btm-sec'}>
                        <Link to={'/terms'}>
                            <button className={'home__pg-get-strt'}>SIGN UP</button></Link>
                        <Link to={'/sign-in'} className={'home__pg-sgn-in'}> SIGN IN</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default HomePage;
