import React, { Component } from 'react'
import './scan-start.css';
// @assets/icons
import LeftArrow from '../../assets/icons/left-arrow.svg';
import Level from '../../assets/icons/level.svg';
import Spin from '../../assets/icons/spin.svg';
import Share from '../../assets/icons/share.svg';
import { Link } from 'react-router-dom';
class ScanStart extends Component {
    render() {
        return (
            <div className='scan-strt'>
                <div className='scan-strt__cnt'>    
                    <div className='scan-strt__btn-wrp'>
                      <Link to={'/demo'}> <button className='scan-strt__back-btn'>
                            <img src={LeftArrow} className='scan-strt__lft-ar' alt='left arrow' />
                            <label>BACK</label>
                        </button></Link> 
                    </div>
                    <div className='scan-strt__top-sec'>
                        <p className='scan-strt__hw-wrks'>How It Works</p>
                        <div className='scan-strt__hw-spin'>
                            <p>You’re  a 360° spin from </p>
                            <p>fashion that fits, faster.</p>
                        </div>
                        <div className='scan-strt__mid-sec'>
                            <div className='scan-strt__mid-inst'>
                                <p>STEP 1</p>
                                <img alt='level' src={Level} />
                                <p>Level</p>
                            </div>
                            <div className='scan-strt__mid-inst'>
                                <p>STEP 2</p>
                                <img alt='spin' src={Spin} />
                                <p>Spin</p>
                            </div>
                            <div className='scan-strt__mid-inst'>
                                <p>STEP 3</p>
                                <img alt='share' src={Share} />
                                <p>Share</p>
                            </div>
                        </div>
                    </div>
                    <div className='scan-strt__scn-bwrp'>
                      <Link to={'/vol'}>  <button className='scan-strt__scan-btn'>BEGIN SCAN</button></Link>
                    </div>
                    <div className='scan-strt__btm-sec'>
                        <div className={'scan-strt__bt-sec-cnt'}>
                            <div className={'scan-strt__bt-lf-arc'}></div>
                            <div className={'scan-strt__bt-ball'}></div>
                            <div className={'scan-strt__bt-rg-arc'}></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ScanStart;