import { Component } from 'react';
import './large-button.css';

class LargeButton extends Component {
    render() {


        const { type, name, className,disabled, onClick } = this.props;

        return (
            <div>
                <button
                    className={className}
                    onClick={onClick}
                    disabled={disabled}
                    type={type}>
                    {name}
                </button>
            </div>
        )
    }
}

export default LargeButton;