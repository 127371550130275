import React, { Component } from 'react';
import './re-enter-new-pwd-page.css';
import ReactTooltip from "react-tooltip";

// @components
import Button from './../../components/button/button';

// @assets/images
import bgTop from '../../assets/images/bg-top.jpg';

// @assets/logo
import ettaLogo from '../../assets/logo/etta-logo.svg';

// @assets/icons
import InfoAltIcon from '../../assets/icons/info-alt.svg';
import { Link } from 'react-router-dom';
import axios from 'axios';

class ReEnterNewPwdPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            renewpass: ''

        }
    }

    nameHandler = (event) => {
        this.setState({
            renewpass: event.target.value
        })
    };



    continueHandler = (renewpass) => {
        localStorage.setItem('renewpass', renewpass);
        var userid = localStorage.getItem("user_id");
        var pass2 = localStorage.getItem("newpass");
        console.log(localStorage.getItem("user_id"));
        var formData = new FormData();
        // var status;
        formData.append("confirm_password", this.state.renewpass);
        formData.append("user_id", userid);
        formData.append("password", pass2);


        axios.post('https://ettaapi1.coitor.com/resetpassword', formData)
            .then(res => {
                console.log(res);
                console.log(res.data.msg);



            })
            .catch((err) => { console.log(err.response.data.message); console.log(err.response) });

        return this.props.history.push('/re-enter-pwd-field');
    }

    

    render() {
        return (
            <div className={'re-np-pg__wrp'}>

                <div className={'re-np-pg__dgn-wrp'}>
                    <img src={bgTop}
                        className={'re-np-pg__bg-dgn'}
                        alt={'background-design'}
                    />
                    <img src={ettaLogo}
                        className={'re-np-pg__logo'}
                        alt={'etta-design'}
                    />
                </div>

                <div className={'re-np-pg__cnt-wrp'}>
                    {/* <span className={'cnp-pg__pop-wrap'}> */}
                        {/* <div className={'cnp-pg__pop-up'}>
                                <p>{'At least 8 characters'}</p>
                                <p>{'uppercase and lowercase letters.'}</p>
                                <p>{'letters and numbers.'}</p>
                                <p>{'one special character, e.g., ! @ # ? }'}</p>
                        </div> */}
                        <div className={'re-np-pg__qa-wrp'}>
                            <p className={'re-np-pg__wh-qs'}>Re-enter new password.&#160;
                                <img className='re-np-pg__info-alt' src={InfoAltIcon} 
                                    alt='info-alt' 
                                    data-tip data-for="registerTip" data-event="click" data-delay-hide="3000"
                                />
                            </p>
                            <Link to={'/re-enter-pwd-field'}>
                                <input
                                    type={'password'}
                                    placeholder={'Your Password'}
                                    name="renewpass" 
                                    onChange={this.nameHandler}
                                    value={this.state.renewpass}
                                    className={'re-np-pg__an-em'} 
                                />
                            </Link>
                            <ReactTooltip id="registerTip" place="top" effect="solid" type="light" 
                                offset="{'left': 100}"
                                arrow-color="#333333"
                                className="customClass"
                                arrowColor="#e0e0ff"
                                afterShow={() => { setTimeout(ReactTooltip.hide(),3000)}}
                            >
                                <div className="tooltip-info">
                                    <p>{'At least 8 characters'}</p>
                                    <p>{'uppercase and lowercase letters.'}</p>
                                    <p>{'letters and numbers.'}</p>
                                    <p>{'one special character, e.g., ! @ # ? }'}</p>
                                </div>
                            </ReactTooltip>
                        </div>
                    {/* </span> */}

                    <div className={'re-np-pg__btn-wrp'}>
                        <Link to={'/create-new-password'}><Button
                            className={'back-btn'}
                            type={'button'}
                            onClick={this.backBtnHandler}
                            name={'BACK'}
                        /></Link>
                        <span style={{ marginRight: '15px' }}></span>
                        <Button
                            className={'continue-btn'}
                            type={'button'}
                            onClick={() => this.continueHandler(this.state.renewpass)}
                            disabled={this.state.renewpass.length !== 0 ? false : true}

                            name={'CONTINUE'}
                        />
                    </div>



                </div>
            </div>
        )
    }
}

export default ReEnterNewPwdPage;